/* eslint-disable quotes */
/* eslint-disable camelcase */
import React from 'react';
import { IconButton, InputAdornment } from '@mui/material';
import { Close as CloseIcon } from '@mui/icons-material';
import { useDispatch, useSelector } from 'react-redux';
import {
  removePortfolioCoins,
  updateAllocation,
  addPortfolioCoin,
} from '../../../../../../../../reducers/portfolioReducer';
import {
  setNotification,
  setNotificationShow,
  setCurrentFocus,
  setCurrentFocusData,
} from '../../../../../../../../reducers/globalReducer';
import { StyledTableRow, StyledNumberField } from './styles';
import { StyledTableCell } from '../../styles';
import {
  parseNumber,
  parseOutputNoRounding,
} from '../../../../../../../../utils/parseNumber';
import { handleKeyDownNumber } from '../../../../../../../../utils/keyEvent';

const PortfolioTableItem = ({
  data,
  dataBackup,
  capitalAmount,
  cashAmount,
  totalPercentage,
  isDisable,
}) => {
  const currency = useSelector((state) => state.currency);
  const assetsData = useSelector((state) => state.portfolio.list);
  const {
    id,
    uuid,
    displayName,
    price,
    percentage,
    amount,
    quantity,
    selected,
  } = data;

  const dispatch = useDispatch();

  // useEffect(() => {
  //   if (capitalAmount !== 0) {
  //     const newAmount = parseNumber(
  //       (capitalAmount * (new_percentage || percentage)) / 100
  //     );
  //     const newQuantity = parseNumber(newAmount / price);
  //     dispatch(
  //       updateAllocation(
  //         uuid,
  //         new_percentage || percentage,
  //         newQuantity,
  //         newAmount
  //       )
  //     );
  //   }
  // }, [capitalAmount]);

  const updateCash = (nIndex, _percentage, _amount) => {
    if (_amount > 0) {
      dispatch(
        updateAllocation(assetsData[nIndex].uuid, _percentage, _amount, _amount)
      );
    } else {
      dispatch(updateAllocation(assetsData[nIndex].uuid, 0, 0, 0));
    }
  };

  const addCash = (_percentage, _amount) => {
    dispatch(
      addPortfolioCoin([
        {
          id: currency,
          displayName: currency,
          ticker: currency,
          uuid: currency,
          assetClass: 1,
          tags: [],
          percentage: _percentage,
          price: 1,
          quantity: _amount,
          amount,
          selected: true,
        },
      ])
    );
  };

  const handleUpdateAllocation = (newPercentage, newQuantity, newAmount) => {
    const parsedNewPercentage = parseNumber(newPercentage);
    const parsedNewAmount = parseNumber(newAmount);
    const availablePercentage = totalPercentage - parsedNewPercentage;

    if (parsedNewPercentage > 100 || availablePercentage < 0) {
      dispatch(setNotificationShow(true));
      dispatch(setNotification("Total weight can't be over 100%"));
    } else {
      const cashIndex = assetsData.findIndex((el) => el.id === currency);
      if (parseOutputNoRounding(totalPercentage, 2) <= 100) {
        dispatch(updateAllocation(uuid, newPercentage, newQuantity, newAmount));

        if (newPercentage <= dataBackup.percentage && capitalAmount > 0) {
          if (cashIndex !== -1) {
            const updatedAmount =
              assetsData[cashIndex].amount + (amount - parsedNewAmount);
            const updatedPercentage = parseNumber(
              (updatedAmount / capitalAmount) * 100
            );

            updateCash(cashIndex, updatedPercentage, updatedAmount);
          } else {
            addCash(
              dataBackup.percentage - parsedNewPercentage,
              dataBackup.amount - parsedNewAmount
            );
          }
        }
      }
    }
  };

  const handleChangePercentage = (event) => {
    handleCheckValid(event);
    const newPercentage = event.target.value;
    const parsedNewPercentage = parseNumber(newPercentage);
    if (parsedNewPercentage >= 0) {
      const newAmount = parseNumber(
        (capitalAmount * parsedNewPercentage) / 100
      );
      const newQuantity = parseNumber(newAmount / price);
      if (newAmount - amount > cashAmount) {
        dispatch(setNotificationShow(true));
        dispatch(setNotification('You need to add more cash'));
      } else {
        handleUpdateAllocation(newPercentage, newQuantity, newAmount);
        if (parsedNewPercentage > dataBackup.percentage) {
          const timestamp = new Date().getTime();
          dispatch(setCurrentFocus(`weight${timestamp}`));
          dispatch(setCurrentFocusData(uuid, percentage, newPercentage));
        }
      }
    } else {
      dispatch(setNotificationShow(true));
      dispatch(setNotification("You can't input negative value for weight"));
    }
  };

  const handleChangeAmount = (event) => {
    handleCheckValid(event);
    const newAmount = event.target.value;
    const parsedNewAmount = parseNumber(newAmount);
    if (parsedNewAmount >= 0) {
      if (parsedNewAmount - amount > cashAmount) {
        dispatch(setNotificationShow(true));
        dispatch(setNotification('You need to add more cash'));
      } else {
        const newQuantity = parseNumber(parsedNewAmount / price);
        const newPercentage = parseNumber(
          100 / (capitalAmount / parsedNewAmount)
        );
        handleUpdateAllocation(newPercentage, newQuantity, newAmount);
        if (newPercentage > dataBackup.percentage) {
          const timestamp = new Date().getTime();
          dispatch(setCurrentFocus(`amount${timestamp}`));
          dispatch(setCurrentFocusData(uuid, percentage, newPercentage));
        }
      }
    } else {
      dispatch(setNotificationShow(true));
      dispatch(setNotification("You can't input negative value for amount"));
    }
  };

  const handleChangeQuantity = (event) => {
    handleCheckValid(event);
    const newQuantity = event.target.value;
    const parsedNewQuantity = parseNumber(newQuantity);
    if (parsedNewQuantity >= 0) {
      const newAmount = parseNumber(price * parsedNewQuantity);
      const newPercentage = parseNumber(100 / (capitalAmount / newAmount));
      if (newAmount - amount > cashAmount) {
        dispatch(setNotificationShow(true));
        dispatch(setNotification('You need to add more cash'));
      } else {
        handleUpdateAllocation(newPercentage, newQuantity, newAmount);
        if (newPercentage > dataBackup.percentage) {
          const timestamp = new Date().getTime();
          dispatch(setCurrentFocus(`quantity${timestamp}`));
          dispatch(setCurrentFocusData(uuid, percentage, newPercentage));
        }
      }
    } else {
      dispatch(setNotificationShow(true));
      dispatch(setNotification("You can't input negative value for quantity"));
    }
  };

  const handleCheckValid = (event) => {
    if (!event.target.value) {
      dispatch(setNotificationShow(true));
      dispatch(setNotification('Please input valid number'));
    }
  };

  const handleRemoveCoin = () => {
    dispatch(removePortfolioCoins(uuid));
    if (uuid === currency || id === currency) {
      const availablePercentage = totalPercentage - percentage;

      assetsData
        .filter((el) => el.id !== currency)
        .map((el) => {
          const newPercentage =
            el.percentage +
            parseFloat(
              (percentage * ((el.percentage / availablePercentage) * 100)) / 100
            );
          const newAmount = parseFloat((capitalAmount * newPercentage) / 100);
          const newQuantity = parseFloat(newAmount / el.price);
          return dispatch(
            updateAllocation(el.uuid, newPercentage, newQuantity, newAmount)
          );
        });
    } else {
      const cashIndex = assetsData.findIndex((el) => el.id === currency);
      if (cashIndex !== -1) {
        const updatedPercentage =
          assetsData[cashIndex].percentage + parseFloat(percentage);
        const updatedAmount = assetsData[cashIndex].amount + parseFloat(amount);
        dispatch(
          updateAllocation(
            assetsData[cashIndex].uuid,
            updatedPercentage,
            updatedAmount,
            updatedAmount
          )
        );
      } else {
        addCash(percentage, amount);
      }
    }
  };

  return (
    <StyledTableRow className="new">
      <StyledTableCell component="th" scope="row">
        {displayName}
      </StyledTableCell>
      <StyledTableCell width={100}>
        <StyledNumberField
          type="number"
          value={parseOutputNoRounding(percentage, 2)}
          endAdornment={<InputAdornment position="end">%</InputAdornment>}
          inputProps={{
            min: 0,
            max: 100,
            step: 0.01,
          }}
          inputMode="numeric"
          pattern="[0-9]*"
          variant="standard"
          disabled={isDisable || !selected || id === currency}
          onChange={handleChangePercentage}
          onKeyDown={handleKeyDownNumber}
        />
      </StyledTableCell>
      <StyledTableCell>
        <StyledNumberField
          type="number"
          value={parseOutputNoRounding(quantity, 6)}
          inputProps={{
            min: 0,
            step: 0.000001,
          }}
          inputMode="numeric"
          pattern="[0-9]*"
          variant="standard"
          disabled={
            isDisable || !selected || capitalAmount === 0 || id === currency
          }
          onChange={handleChangeQuantity}
          onKeyDown={handleKeyDownNumber}
        />
      </StyledTableCell>
      <StyledTableCell>
        <StyledNumberField
          type="number"
          value={parseOutputNoRounding(amount, 2)}
          inputProps={{
            min: 0,
            step: 0.01,
          }}
          inputMode="numeric"
          pattern="[0-9]*"
          variant="standard"
          disabled={
            isDisable || !selected || capitalAmount === 0 || id === currency
          }
          onChange={handleChangeAmount}
          onKeyDown={handleKeyDownNumber}
        />
      </StyledTableCell>
      <StyledTableCell align="right">
        {selected && (
          <IconButton onClick={handleRemoveCoin}>
            <CloseIcon />
          </IconButton>
        )}
      </StyledTableCell>
    </StyledTableRow>
  );
};

export default PortfolioTableItem;
