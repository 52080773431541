export const checkTimeAllowance = (date, hours) => {
  const providedTimestamp = new Date(date);
  const currentTime = new Date();
  const timestampPlusOneHour = new Date(
    providedTimestamp.getTime() + hours * 3600 * 1000
  );
  if (currentTime > timestampPlusOneHour) {
    return true;
  }
  return false;
};

export const isStockMarketOpen = () => {
  const now = new Date();
  
  // Convert to US/Eastern time
  const options = { timeZone: 'America/New_York', hour12: false };
  const year = now.toLocaleString('en-US', { ...options, year: 'numeric' });
  const month = now.toLocaleString('en-US', { ...options, month: '2-digit' });
  const day = now.toLocaleString('en-US', { ...options, day: '2-digit' });
  // const hours = now.toLocaleString('en-US', { ...options, hour: '2-digit', minute: '2-digit' }).split(':');
  
  const dayOfWeek = now.toLocaleString('en-US', { ...options, weekday: 'long' });

  // Define the market opening and closing times
  const marketOpen = new Date(`${year}-${month}-${day}T09:30:00-04:00`);
  const marketClose = new Date(`${year}-${month}-${day}T16:00:00-04:00`);

  // Check if today is a weekday (Monday to Friday)
  if (['Saturday', 'Sunday'].includes(dayOfWeek)) {
      return false;
  }

  // Check if the current time is within market hours
  if (now >= marketOpen && now <= marketClose) {
      return true;
  } 
      return false;
};
