/* eslint-disable camelcase */
import React, { useState, useEffect } from 'react';
import { Card, Image, Row } from 'react-bootstrap';
import { useDispatch, useSelector } from 'react-redux';
import Slider from 'react-slick';
// eslint-disable-next-line import/no-extraneous-dependencies
import moment from 'moment-timezone';
import {
  Stack,
  DialogActions,
  DialogContent,
  DialogContentText,
  Typography,
  Box,
  Tooltip,
  useMediaQuery,
  IconButton,
  CircularProgress,
  Skeleton,
  Link,
} from '@mui/material';
import { useTheme } from '@mui/material/styles';
import InfoIcon from '@mui/icons-material/Info';
import { PlayArrow, Edit, Check } from '@mui/icons-material';
import CDialog from '../../../../components/Base/CDialog';
import MButton from '../../../../components/Common/MButton';
import H5 from '../../../../components/Common/Titles/h5';
import SimpleBadge from '../../../../components/Common/Badge/SimpleBadge';
import FrontCardView from '../../../../assets/img/Common/front-card-view.svg';
import BackCardView from '../../../../assets/img/Common/back-card-view.svg';
import Paragraph from '../../../../components/Common/Paragraph';
// import FirstElement from '../Modal/firstelement';
// import SecondElement from '../Modal/secondelement';
// import PortfolioTraking from '../Modal/PortfolioTraking';
import PieChartEl from '../../../../components/PieChartEl';
import AreaChartEl from '../../../../components/AreaChartEl';
import ColorIndicator from '../../../../components/ColorIndicator';
import CoinElement from './CoinElement';
import PortfolioModal from '../../../../components/Base/PortfolioManagement/PortfolioModal/PortfolioManage';
import {
  addPortfolioCoins,
  addPortfolioCoinsBackup,
  setCurrentPortfolio,
} from '../../../../reducers/portfolioReducer';
import { StyledButton } from '../AggregatePortfolio/styles';
import { archivePortfolio, updatePortfolio } from '../../../../lib/assetsApi';
import {
  setNotificationShow,
  setNotification,
} from '../../../../reducers/globalReducer';
import { Wrapper, RightActions, StyledNameInput } from './styles';
import PortfolioUpdate from '../../../PortfolioCreation/UpdatePortfolio';
import { StyledPriceChip } from '../../../../components/Base/Table/styles';
import { parseChartDateLocale } from '../../../../utils/parseDate';
import CustomDatePicker from './CustomDatePicker';

const durationList = [
  {
    id: 'tracking_period',
    label: 'Tracking Period',
  },
  {
    id: '24H',
    label: '24H',
  },
  {
    id: '1M',
    label: '1M',
  },
  {
    id: '1Y',
    label: '1Y',
  },
];

const PortofolioElement = ({
  children,
  data,
  type,
  showLoading,
  handleShowLoading,
  handleModalShow,
  handleModalClose,
  handleFetchPortfolio,
  ...props
}) => {
  const dispatch = useDispatch();
  const theme = useTheme();
  const isMobile = useMediaQuery((th) => th.breakpoints.down('md'));
  const tooltipDescription =
    'The risk score is based on portfolio-weighted volatility and the covariance of individual assets within the portfolio. For further details, please refer to our';

  const user = useSelector((state) => state.user.userInfo);
  // const currency = useSelector((state) => state.currency);
  const {
    id,
    name,
    address,
    chain,
    assets,
    capital,
    new_capital,
    change24H,
    change1M,
    change1Y,
    change_portfolio_age,
    tracked,
    live,
    connected,
    manual,
    points24H,
    points1M,
    points1Y,
    risk_score,
    points_portfolio_age,
    created_at,
    updatedStartDate,
  } = data;
  // const rate = parseFloat(((new_capital - capital) / capital) * 100);
  const rate = change_portfolio_age;
  const [cumulation, setCumulation] = useState(rate);
  const [totalValue, setTotalValue] = useState(0);
  const [hover, setHover] = useState(false);
  const [hoverEdit, setHoverEdit] = useState(false);
  const [frontView, setFrontView] = useState(true);
  const [currentDuration, setCurrentDuration] = useState(durationList[1]);
  const [points, setPoints] = useState(points24H ? points24H : []);
  const [editMode, setEditMode] = useState(false);
  const [portfolioName, setPortfolioName] = useState(name);
  const [mouseOut, setMouseOut] = useState(true);
  const [activeGraphData, setActiveGraphData] = useState({
    change: change24H || 0,
    price: new_capital || capital,
    time: moment.utc(),
  });

  // eslint-disable-next-line no-confusing-arrow
  const coinsToShow = assets?.sort((a, b) =>
    a.value > b.value ? -1 : b.value > a.value ? 1 : 0
  );
  const token = useSelector((state) => state.user.userToken);
  const [showPortfolioModal, setShowPortfolioModal] = useState(false);
  const [showConfirmModal, setShowConfirmModal] = useState(false);
  const [isUpdating, setIsUpdating] = useState(false);

  // const setModalShow = () => {
  //   const value = {
  //     name,
  //     body: [
  //       {
  //         id: 0,
  //         title: 'Stake',
  //         el: (
  //           <FirstElement
  //             title={name}
  //             // index={index}
  //             id={id}
  //             handleModalClose={() => handleModalClose()}
  //           />
  //         ),
  //       },
  //       {
  //         id: 1,
  //         title: 'Buy',
  //         el: (
  //           <SecondElement
  //             title={name}
  //             id={id}
  //             coins={assets}
  //             handleModalClose={() => handleModalClose()}
  //           />
  //         ),
  //       },
  //     ],
  //   };

  //   handleModalShow(id, value);
  // };

  // const handleCompare = () => {
  //   const value = {
  //     name,
  //     body: (
  //       <PortfolioTraking
  //         title={name}
  //         // index={index}
  //         id={id}
  //         handleModalClose={() => handleModalClose()}
  //       />
  //     ),
  //   };

  //   handleModalShow(id, value);
  // };

  const handleChangeView = () => {
    setFrontView(!frontView);
  };

  // const handleGoToPortfolio = () => {
  //   history.push('/portfolio/1');
  // };

  const handleManage = () => {
    const timestamp = new Date().getTime();

    dispatch(
      addPortfolioCoins(
        assets.map((el) => {
          return {
            id: el.id,
            assetClass: el.assetClass,
            displayName: el.displayName,
            logo: el.logo,
            percentage: el.new_percentage || el.percentage,
            amount: el.new_amount || el.amount,
            quantity: el.quantity,
            price: el.price,
            tags: el.tags,
            ticker: el.ticker,
            uuid: el.id + timestamp,
            selected: true,
          };
        })
      )
    );
    dispatch(
      addPortfolioCoinsBackup(
        assets.map((el) => {
          return {
            id: el.id,
            assetClass: el.assetClass,
            displayName: el.displayName,
            logo: el.logo,
            percentage: el.new_percentage || el.percentage,
            amount: el.new_amount || el.amount,
            quantity: el.quantity,
            price: el.price,
            tags: el.tags,
            ticker: el.ticker,
            uuid: el.id + timestamp,
            selected: true,
          };
        })
      )
    );
    dispatch(setCurrentPortfolio(id, name, new_capital || capital));
    setShowPortfolioModal(true);
  };

  const handleCloseManage = () => {
    setShowPortfolioModal(false);
  };

  const handleSelectDuration = (duration) => {
    setCurrentDuration(duration);
    if (duration.id === '24H') {
      setPoints(points24H);
      setActiveGraphData({
        change: change24H || 0,
        price: new_capital || capital,
        time: moment.utc(),
      });
    } else if (duration.id === '1M') {
      setPoints(points1M);
      setActiveGraphData({
        change: change1M || 0,
        price: new_capital || capital,
        time: moment.utc(),
      });
    } else if (duration.id === '1Y') {
      setPoints(points1Y);
      setActiveGraphData({
        change: change1Y || 0,
        price: new_capital || capital,
        time: moment.utc(),
      });
    } else {
      setPoints(points_portfolio_age);
      setActiveGraphData({
        change: change_portfolio_age || 0,
        price: new_capital || capital,
        time: moment.utc(),
      });
    }
  };

  const settings = {
    infinite: false,
    speed: 500,
    slidesToShow: 5,
    slidesToScroll: 5,
  };

  const handleDelete = () => {
    setShowConfirmModal(true);
  };

  const closeConfirmationModal = () => {
    setShowConfirmModal(false);
  };

  const handleArchivePortfolio = async () => {
    handleShowLoading(true);
    const archivedPortfolio = await archivePortfolio(id, token);

    if (archivedPortfolio.status === 200 || archivedPortfolio.status === 201) {
      handleShowLoading(false);
      dispatch(setNotificationShow(false));
      closeConfirmationModal();
      handleFetchPortfolio();
    } else {
      handleShowLoading(false);
      dispatch(setNotificationShow(true));
      dispatch(setNotification(archivedPortfolio.error));
    }
  };

  const handleTrack = async () => {
    handleShowLoading(true);
    const trackedPortfolio = data;
    trackedPortfolio.tracked = !tracked;
    if (capital === 0) {
      trackedPortfolio.new_capital = 1;
      trackedPortfolio.capital = 1;
      trackedPortfolio.assets = trackedPortfolio.assets.map((asset) => {
        return {
          ...asset,
          amount: (1 * asset.percentage) / 100,
          quantity: (1 * asset.percentage) / 100 / asset.price,
        };
      });
    }

    const updatedPortfolio = await updatePortfolio(trackedPortfolio, token);

    if (updatedPortfolio.status === 200 || updatedPortfolio.status === 201) {
      handleShowLoading(false);
      dispatch(setNotificationShow(false));
      closeConfirmationModal();
      handleFetchPortfolio();
    } else {
      handleShowLoading(false);
      dispatch(setNotificationShow(true));
      dispatch(setNotification(updatedPortfolio.error));
    }
  };

  const handleChangeName = (event) => {
    setPortfolioName(event.target.value);
  };

  const handleEditName = async () => {
    if (editMode && portfolioName !== name) {
      setIsUpdating(true);

      const portfolioToUpdate = data;
      portfolioToUpdate.name = portfolioName;

      const updatedPortfolio = await updatePortfolio(portfolioToUpdate, token);

      if (updatedPortfolio.status === 200 || updatedPortfolio.status === 201) {
        setIsUpdating(false);
      } else {
        setIsUpdating(false);
        setPortfolioName(name);
        dispatch(setNotificationShow(true));
        dispatch(setNotification(updatedPortfolio.error));
      }
    }
    setEditMode(!editMode);
    setHoverEdit(false);
  };

  const handleActiveData = (activeData) => {
    setMouseOut(false);
    setActiveGraphData(activeData);
  };

  const handleMouseLeave = () => {
    setActiveGraphData({
      change:
        (currentDuration.id === '24H'
          ? change24H
          : currentDuration.id === '1M'
          ? change1M
          : currentDuration.id === '1Y'
          ? change1Y
          : change_portfolio_age) || 0,
      price: new_capital || capital,
      time: moment.utc(),
    });
    setMouseOut(true);
  };

  useEffect(() => {
    if (mouseOut) {
      setCumulation(rate);
    } else {
      const newCumulation = parseFloat(
        ((activeGraphData.price - capital) / capital) * 100
      );
      setCumulation(newCumulation);
    }
  }, [activeGraphData]);

  useEffect(() => {
    // const cashElement = assets.find((el) => el.displayName === currency);
    // if (cashElement) {
    //   setTotalValue(
    //     (new_capital || capital)
    //   );
    // } else {
      setTotalValue(new_capital || capital);
    // }
  }, [assets]);

  return (
    <Wrapper
      className="h-100"
      {...props}
      onMouseEnter={() => setHover(true)}
      onMouseLeave={() => setHover(false)}
    >
      <Card className="h-100">
        {frontView ? (
          <>
            <Card.Header className="d-flex justify-content-between align-items-center">
              {tracked ? (
                showLoading ? (
                  <Skeleton width={80} />
                ) : (
                  <Tooltip title="Total Return">
                    <Stack direction="row">
                      {rate ? (
                        <PlayArrow
                          color={rate >= 0 ? 'success' : 'warning'}
                          sx={{ rotate: rate >= 0 ? '270deg' : '90deg' }}
                        />
                      ) : (
                        ''
                      )}
                      <Typography fontWeight={600}>
                        {rate
                          ? `${rate > 0 ? '+' : ''}${rate.toFixed(2)}%`
                          : ''}
                      </Typography>
                    </Stack>
                  </Tooltip>
                )
              ) : null}
              {showLoading ? (
                <Skeleton width={100} height={36} />
              ) : isUpdating ? (
                <CircularProgress color="white" size={36} />
              ) : (
                <Stack
                  flexDirection="row"
                  alignItems="center"
                  onMouseEnter={() => setHoverEdit(true)}
                  onMouseLeave={() => setHoverEdit(false)}
                  sx={{ minHeight: 36 }}
                >
                  {editMode ? (
                    <StyledNameInput
                      value={portfolioName}
                      onChange={handleChangeName}
                    />
                  ) : (
                    <H5 className="mb-0 text-center">{portfolioName}</H5>
                  )}
                  {hoverEdit || editMode ? (
                    <IconButton onClick={handleEditName}>
                      {editMode ? (
                        <Check
                          fontSize="small"
                          sx={{ color: theme.palette.white }}
                        />
                      ) : (
                        <Edit
                          fontSize="small"
                          sx={{ color: theme.palette.white }}
                        />
                      )}
                    </IconButton>
                  ) : null}
                </Stack>
              )}
              {showLoading ? (
                <Skeleton width={30} />
              ) : (
                <SimpleBadge>
                  {connected ? 'Connected' : live ? 'Live' : 'Virtual'}
                </SimpleBadge>
              )}
            </Card.Header>
            <Card.Body className="position-relative">
              {tracked ? (
                <RightActions className="d-flex flex-column">
                  {
                    showLoading ? (
                      <Skeleton variant="circular" width={24} height={24} />
                    ) : (
                      <span
                        aria-hidden="true"
                        className="rightAction rightAction--invert text-center"
                        onClick={handleChangeView}
                        onKeyDown={handleChangeView}
                      >
                        <Image fluid src={FrontCardView} alt="" />
                      </span>
                    )
                    /* <span
                  aria-hidden="true"
                  className="rightAction rightAction--expand text-center"
                  onClick={() => handleGoToPortfolio(history)}
                  onKeyDown={() => handleGoToPortfolio(history)}
                >
                  <Image fluid src={Expand} alt="" />
                </span> */
                  }
                </RightActions>
              ) : null}
              {showLoading ? (
                <Skeleton width={200} height={30} sx={{ mx: 'auto' }} />
              ) : points_portfolio_age && points_portfolio_age.length > 0 ? (
                <CustomDatePicker
                  timeZ={user?.timezone}
                  portfolioData={data}
                  handleFetchPortfolio={handleFetchPortfolio}
                />
              ) : null}
              {showLoading ? (
                <Skeleton width={120} height={30} />
              ) : (
                <Paragraph
                  fontsize="24px"
                  fontweight={700}
                  className="d-inline align-bottom"
                >
                  $
                  {totalValue.toLocaleString(undefined, {
                    minimumFractionDigits: 2,
                    maximumFractionDigits: 2,
                  })}
                </Paragraph>
              )}

              <div className="wrappPie">
                {showLoading ? (
                  <Skeleton
                    variant="circular"
                    width={250}
                    height={250}
                    sx={{ mx: 'auto' }}
                  />
                ) : (
                  <PieChartEl data={assets} />
                )}
              </div>
              {(hover || isMobile) && !showLoading ? (
                <div className="d-flex justify-content-center buttons__wrapp">
                  {connected && !manual ? null : connected ? (
                    <PortfolioUpdate
                      portfolioId={id}
                      publicAddress={address}
                      chainId={chain}
                    />
                  ) : (
                    <MButton
                      sm="true"
                      purple="true"
                      className="me-1"
                      onClick={handleManage}
                    >
                      Manage
                    </MButton>
                  )}
                  {!tracked ? (
                    <MButton
                      sm="true"
                      purple="true"
                      className="me-1"
                      onClick={() => handleTrack()}
                    >
                      Track
                    </MButton>
                  ) : null}
                  <MButton sm="true" purple="true" onClick={handleDelete}>
                    Delete
                  </MButton>
                </div>
              ) : null}
            </Card.Body>
          </>
        ) : (
          <>
            <Card.Body className="position-relative secondView">
              <div className="d-flex flex-column w-100">
                <Paragraph
                  fontsize="24px"
                  fontweight={600}
                  className="d-inline align-bottom me-5 lh-1"
                >
                  {name}
                  {points_portfolio_age && points_portfolio_age.length > 0 ? (
                    <Typography
                      component="span"
                      variant="caption"
                      fontWeight={500}
                    >
                      {' '}
                      (
                      {parseChartDateLocale(
                        updatedStartDate ? updatedStartDate : created_at,
                        user?.timezone,
                        false
                      )}
                      )
                    </Typography>
                  ) : null}
                </Paragraph>
                <Paragraph
                  fontsize="24px"
                  fontweight={700}
                  className="d-inline align-bottom"
                >
                  $
                  {activeGraphData.price.toLocaleString(undefined, {
                    minimumFractionDigits: 2,
                    maximumFractionDigits: 2,
                  })}
                  <Typography
                    component="span"
                    variant="caption"
                    fontWeight={500}
                  >
                    {' '}
                    (
                    {parseChartDateLocale(
                      activeGraphData.time,
                      user?.timezone,
                      currentDuration.id === '24H' ? true : false
                    )}
                    )
                  </Typography>
                </Paragraph>
                <Paragraph fontsize="16px" fontweight={500}>
                  Risk score:
                  <sup>
                    <Tooltip
                      title={
                        <>
                          {tooltipDescription}{' '}
                          <Link
                            href="https://divercefi.com/portfolio-risk-score"
                            target="_blank"
                            style={{
                              color: 'inherit',
                              textDecoration: 'underline',
                            }}
                          >
                            methodology
                          </Link>
                          .
                        </>
                      }
                      sx={{ color: theme.palette.lightGrey3 }}
                    >
                      <InfoIcon fontSize="50%" />
                    </Tooltip>
                  </sup>{' '}
                  <ColorIndicator number={Math.round(risk_score)} dark />
                </Paragraph>
                <Stack flexDirection="column" gap={1} mt={1} mb={2}>
                  <Box>
                    <Stack
                      flexDirection="row"
                      alignItems="center"
                      columnGap={0.5}
                    >
                      <Typography
                        variant="caption"
                        fontWeight={600}
                        sx={{ whiteSpace: 'nowrap' }}
                      >
                        Total return
                      </Typography>
                      <StyledPriceChip
                        label={`${`${
                          cumulation > 0 ? '+' : ''
                        }${cumulation.toFixed(2)}%`}`}
                        color={cumulation >= 0 ? 'success' : 'warning'}
                        size="small"
                        sx={{
                          color:
                            cumulation >= 0
                              ? theme.palette.primary.main
                              : theme.palette.white,
                        }}
                      />
                    </Stack>
                    {currentDuration.id !== 'tracking_period' ? (
                      <Stack
                        flexDirection="row"
                        alignItems="center"
                        columnGap={0.5}
                        mt={1}
                      >
                        <Typography
                          variant="caption"
                          fontWeight={600}
                          sx={{ whiteSpace: 'nowrap' }}
                        >
                          {currentDuration.label} return
                        </Typography>
                        <StyledPriceChip
                          label={`${`${
                            activeGraphData.change > 0 ? '+' : ''
                          }${activeGraphData.change.toFixed(2)}%`}`}
                          color={
                            activeGraphData.change >= 0 ? 'success' : 'warning'
                          }
                          size="small"
                          sx={{
                            color:
                              activeGraphData.change >= 0
                                ? theme.palette.primary.main
                                : theme.palette.white,
                          }}
                        />
                      </Stack>
                    ) : null}
                  </Box>
                  {tracked ? (
                    <Stack
                      direction="row"
                      alignItems="center"
                      justifyContent="flex-end"
                      width="100%"
                      gap={0.5}
                    >
                      {durationList
                        .filter(
                          (duration) =>
                            !(
                              duration.id === 'tracking_period' &&
                              points_portfolio_age &&
                              points_portfolio_age.length < 2
                            )
                        )
                        .map((duration) => (
                          <StyledButton
                            key={duration.id}
                            variant="contained"
                            className={`${
                              duration.id === currentDuration.id ? 'active' : ''
                            } ${
                              duration.id === 'tracking_period'
                                ? 'long_content'
                                : ''
                            } small`}
                            onClick={() => {
                              handleSelectDuration(duration);
                            }}
                          >
                            {duration.label}
                          </StyledButton>
                        ))}
                    </Stack>
                  ) : null}
                </Stack>
              </div>
              <RightActions className="d-flex flex-column">
                <span
                  aria-hidden="true"
                  className="rightAction rightAction--invert text-center"
                  onClick={handleChangeView}
                  onKeyDown={handleChangeView}
                >
                  <Image fluid src={BackCardView} alt="" />
                </span>
                {/* <span className="rightAction rightAction--expand text-center">
                  <Image fluid src={Expand} alt="" />
                </span> */}
              </RightActions>

              <Box
                className="wrappArea"
                sx={{ visibility: tracked ? 'visible' : 'hidden' }}
              >
                {points && points.length > 0 ? (
                  <AreaChartEl
                    id={id}
                    data={points}
                    duration={currentDuration.id}
                    createdAt={created_at}
                    height={200}
                    color="grey"
                    timezone={user?.timezone}
                    periodStartPrice={points[0].price}
                    portfolioEntryPrice={capital}
                    handleActiveData={handleActiveData}
                    handleMouseLeave={handleMouseLeave}
                  />
                ) : (
                  <Stack
                    alignItems="center"
                    justifyContent="center"
                    sx={{ height: 150 }}
                  >
                    <Typography sx={{ fontSize: 14 }}>
                      Reload the page to visualize graph data
                    </Typography>
                  </Stack>
                )}
              </Box>

              {/* <Separator /> */}

              <Row className="justify-content-center mt-3 mb-3">
                <Slider {...settings}>
                  {coinsToShow.map((el) => (
                    <CoinElement data={el} key={el.ticker} />
                  ))}
                </Slider>
              </Row>
            </Card.Body>
          </>
        )}
      </Card>
      <PortfolioModal
        open={showPortfolioModal}
        onClose={handleCloseManage}
        handleShowLoading={(param) => handleShowLoading(param)}
        handleFetchPortfolio={() => handleFetchPortfolio()}
      />
      <CDialog
        open={showConfirmModal}
        title="Delete"
        onClose={closeConfirmationModal}
      >
        <DialogContent>
          <DialogContentText>
            Are you sure you want to delete your portfolio?
          </DialogContentText>
        </DialogContent>
        <DialogActions sx={{ justifyContent: 'center' }}>
          <MButton
            sm="true"
            purple="true"
            width="130px"
            onClick={closeConfirmationModal}
          >
            Cancel
          </MButton>
          <MButton
            sm="true"
            green="true"
            hoverColor={theme.palette.primary.main}
            width="130px"
            onClick={handleArchivePortfolio}
          >
            Confirm
          </MButton>
        </DialogActions>
      </CDialog>
    </Wrapper>
  );
};

export default PortofolioElement;
