const initialState = {
  isConnected: false,
  assets: [],
  selectedAssets: [],
};

export const TOGGLE_ASSET = 'TOGGLE_ASSET';
export const RESET_TOGGLE = 'RESET_TOGGLE';
export const LOAD_ASSETS = 'LOAD_ASSETS';
export const RESET_ASSETS = 'RESET_ASSETS';
export const SOCKET_CONNECTED = 'SOCKET_CONNECTED';
export const SOCKET_DISCONNECTED = 'SOCKET_DISCONNECTED';
export const UPDATE_ASSETS = 'UPDATE_ASSETS';
export const CONNECT_SOCKET = 'CONNECT_SOCKET';
export const DISCONNECT_SOCKET = 'DISCONNECT_SOCKET';
export const SUBSCRIBE_ASSETS = 'SUBSCRIBE_ASSETS';

export const toggleAsset = (id) => {
  return {
    type: TOGGLE_ASSET,
    id,
  };
};

export const resetToggle = (assetClass) => {
  return {
    type: RESET_TOGGLE,
    assetClass,
  };
};

export const loadAssets = (assetClass, data) => ({
  type: LOAD_ASSETS,
  assetClass,
  payload: data,
});

export const resetSelectedAssets = () => {
  return {
    type: RESET_ASSETS,
  };
};

export const connectSocket = () => ({
  type: CONNECT_SOCKET,
});

export const disconnectSocket = () => ({
  type: DISCONNECT_SOCKET,
});

export const socketConnected = () => ({
  type: SOCKET_CONNECTED,
});

export const socketDisconnected = () => ({
  type: SOCKET_DISCONNECTED,
});

export const updateAssets = (assetData) => ({
  type: UPDATE_ASSETS,
  payload: assetData,
});

export const subscribeAssets = (tickers) => ({
  type: SUBSCRIBE_ASSETS,
  payload: tickers,
});

const handleToggleAsset = (state, action) => {
  const selectedAssets = [...state.selectedAssets];
  const index = selectedAssets.findIndex((asset) => asset.id === action.id);
  if (index === -1) {
    const assetToAdd = state.assets.find((asset) => asset.id === action.id);
    if (assetToAdd) {
      assetToAdd.selected = true;
      selectedAssets.push(assetToAdd);
    }
  } else {
    selectedAssets.splice(index, 1);
  }

  return {
    ...state,
    selectedAssets,
  };
};

const assetsReducer = (state = initialState, action) => {
  switch (action.type) {
    case LOAD_ASSETS:
      return {
        ...state,
        assets: action.payload
          .map((asset) => {
            return asset;
          })
          .concat(
            state.assets.filter(
              (asset) => asset.assetClass !== action.assetClass
            )
          ),
      };
    case TOGGLE_ASSET:
      return handleToggleAsset(state, action);
    case RESET_TOGGLE:
      return {
        ...state,
        selectedAssets: state.selectedAssets.filter(
          (asset) => asset.assetClass !== action.assetClass
        ),
      };
    case RESET_ASSETS:
      return {
        ...state,
        selectedAssets: [],
      };
    case UPDATE_ASSETS: {
      const newAsset = action.payload;
      // console.log('UPDATE_ASSETS_LIST', action.payload);
      // console.log('assets:', state.assets);
      const updatedAssetsList = state.assets.map((existingAsset) => {
        const matchingNewAsset = newAsset.symbol.match(/^[A-Z]+/)[0] === existingAsset.ticker;
        if (matchingNewAsset) {
          const hasChanged =
            existingAsset.price !== newAsset.ask;
          // console.log('existingAsset:', newAsset.ask);
          return {
            ...existingAsset,
            price: parseFloat(newAsset.ask),
            highlight: hasChanged,
          };
        }

        return existingAsset;
      });

      return {
        ...state,
        assets: [...updatedAssetsList],
      };
    }
    case SOCKET_CONNECTED:
      return {
        ...state,
        isConnected: true,
      };
    case SOCKET_DISCONNECTED:
      return {
        ...state,
        isConnected: false,
      };
    default:
      return state;
  }
};

export default assetsReducer;
