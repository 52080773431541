import React, { useState, useEffect, useCallback } from 'react';
import { useNavigate } from 'react-router-dom';
import {
  MenuItem,
  Grid,
  Stack,
  Box,
  Typography,
  Skeleton,
  Tooltip,
  useMediaQuery,
  Link,
} from '@mui/material';
import InfoIcon from '@mui/icons-material/Info';
import { useTheme } from '@mui/material/styles';
import { useSelector, useDispatch } from 'react-redux';
// eslint-disable-next-line import/no-extraneous-dependencies
import moment from 'moment-timezone';
import { detectCedeProvider } from '@cedelabs/providers';
import MButton from '../../../../components/Common/MButton';
import CAccordion from '../../../../components/Base/CAccordion';
import PieChartEl from '../../../../components/PieChartEl';
import PortfolioAssetsTable from '../PortfolioAssetsTable';
import AreaChartEl from '../../../../components/AreaChartEl';
import { StyledSelect, StyledButton } from './styles';
import { StyledPriceChip } from '../../../../components/Base/Table/styles';
import {
  getPortfolioAggregate,
  getAssetsByTicker,
  createPortfolio,
  updatePortfolio,
} from '../../../../lib/assetsApi';
import { getAuthToken } from '../../../../lib/auth';
import { parseNumber } from '../../../../utils/parseNumber';
import { getCedeBalance, getPrices } from '../../../../utils/blockchain';
import { setWalletConnected } from '../../../../reducers/walletsReducer';
import {
  setNotificationShow,
  setNotification,
  setUnfinishedPortfolio,
} from '../../../../reducers/globalReducer';
import { CenterBox } from '../../../../components/Base/PortfolioManagement/PortfolioModal/styles';
import { checkArraysEqual } from '../../../../utils';
import { skeletonAggregateTableData } from '../../../../data/skeletonData';
import { parseChartDateLocale } from '../../../../utils/parseDate';
import ColorIndicator from '../../../../components/ColorIndicator';

const durationList = [
  {
    id: 'tracking_period',
    label: 'Tracking Period',
  },
  {
    id: '24H',
    label: '24H',
  },
  {
    id: '1M',
    label: '1M',
  },
  {
    id: '1Y',
    label: '1Y',
  },
];

const AggregatePortfolio = ({
  id,
  title,
  showLoading,
  handleShowLoading,
  handleFetchPortfolio,
}) => {
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const theme = useTheme();

  const [allData, setAllData] = useState([]);
  const [chartData, setChartData] = useState([]);
  const [aggregateData, setAggregateData] = useState([]);
  const [totalCoins, setTotalCoins] = useState([]);
  const [cumulation, setCumulation] = useState(0);

  const myPortfolios = useSelector(
    (state) => state.portfolioExplorer.myPortfolio
  );
  const token = getAuthToken();
  const connectedRedux = useSelector((state) => state.wallets.connected);
  const activeAccountRedux = useSelector(
    (state) => state.wallets.activeAccount
  );
  const currency = useSelector((state) => state.currency);
  const user = useSelector((state) => state.user.userInfo);

  const connected = localStorage.getItem('WalletConnected')
    ? JSON.parse(localStorage.getItem('WalletConnected'))
    : connectedRedux;
  const activeAccount = localStorage.getItem('ActiveAccount')
    ? localStorage.getItem('ActiveAccount')
    : activeAccountRedux;

  const [type, setType] = useState('tracked');
  const [currentDuration, setCurrentDuration] = useState(durationList[1]);
  const [activeGraphData, setActiveGraphData] = useState({
    change: 0,
    price: 0,
    time: moment.utc(),
  });
  const [expanded, setExpanded] = useState(true);
  const [portfolioEntryPrice, setPortfolioEntryPrice] = useState(0);
  const tooltipDescription =
    'The risk score is based on portfolio-weighted volatility and the covariance of individual assets within the portfolio. For further details, please refer to our';
  const isMobile = useMediaQuery((th) => th.breakpoints.down('sm'));

  const handleAccordion = () => {
    setExpanded(!expanded);
  };

  const handleReload = () => {
    if (connected) {
      dispatch(setWalletConnected(false));
      localStorage.removeItem('WalletConnected');
    }
    window.location.reload();
  };

  const getAssets = async (ticker) => {
    if (ticker !== '') {
      try {
        const data = await getAssetsByTicker(ticker);
        if (data.status === 200) {
          return data.data;
        }
      } catch (err) {
        console.log(err);
      }
    }
  };

  const resetAggregateData = (res) => {
    setCurrentDuration(durationList[1]);
    setAggregateData(res);
    setChartData(res.points24H);
    setActiveGraphData({
      change: res.change24H || 0,
      price: res.new_capital || 0,
      time: moment.utc(),
    });
    setPortfolioEntryPrice(res.capital);
    handleShowLoading(false);
  };

  const mergeDuplicatedCoins = async (data, total) => {
    const mergedData = data.reduce((accumulator, el) => {
      const existingIndex = accumulator.findIndex(
        (item) => item.ticker === el.ticker
      );
      if (existingIndex !== -1) {
        accumulator[existingIndex].percentage +=
          total > 0
            ? parseFloat(((el.amount / total) * 100).toFixed(2))
            : parseFloat((el.percentage / allData.length).toFixed(2));
        accumulator[existingIndex].new_percentage +=
          total > 0
            ? parseFloat(
                (((el.new_amount || el.amount) / total) * 100).toFixed(2)
              )
            : parseFloat(
                ((el.new_percentage || el.percentage) / allData.length).toFixed(
                  2
                )
              );
        accumulator[existingIndex].quantity += el.quantity;
        accumulator[existingIndex].amount += el.amount;
        accumulator[existingIndex].new_amount += el.new_amount || el.amount;
      } else {
        accumulator.push({
          displayName: el.displayName,
          assetClass: el.assetClass,
          tags: el.tags,
          price: el.price,
          percentage:
            total > 0
              ? parseFloat(((el.amount / total) * 100).toFixed(2))
              : parseFloat((el.percentage / allData.length).toFixed(2)),
          new_percentage:
            total > 0
              ? parseFloat(
                  (((el.new_amount || el.amount) / total) * 100).toFixed(2)
                )
              : parseFloat(
                  (
                    (el.new_percentage || el.percentage) / allData.length
                  ).toFixed(2)
                ),
          quantity: el.quantity,
          amount: el.amount,
          new_amount: el.new_amount || el.amount,
          cumulation:
            total > 0
              ? parseFloat(
                  (
                    (((el.new_amount || el.amount) - el.amount) / el.amount) *
                    100
                  ).toFixed(2)
                )
              : 0,
          ticker: el.ticker,
          logo: el.logo,
        });
      }
      return accumulator;
    }, []);
    mergedData.sort((a, b) => b.percentage - a.percentage);
    const mergedTickers = mergedData.map((item) => item.ticker).join(',');
    const assetsData = await getAssets(mergedTickers);
    mergedData.map((pData) => {
      const matchingObject = assetsData.find(
        (item) => item.ticker === pData.ticker
      );
      if (matchingObject) {
        pData.price24H = matchingObject.price24H;
      }
      return pData;
    });
    setTotalCoins(mergedData);
  };

  const mergeDuplicatedCoinsCede = (data) => {
    const mergedData = data.reduce((accumulator, el) => {
      const existingIndex = accumulator.findIndex(
        (item) => item.ticker === el.ticker
      );
      if (existingIndex !== -1) {
        accumulator[existingIndex].totalBalance += el.totalBalance;
        accumulator[existingIndex].refTotalBalance += el.refTotalBalance;
      } else {
        accumulator.push({
          ticker: el.ticker,
          totalBalance: el.totalBalance,
          refTotalBalance: el.refTotalBalance,
        });
      }
      return accumulator;
    }, []);
    return mergedData;
  };

  const getCedeVaults = async () => {
    handleShowLoading(true);
    const globalProvider = await detectCedeProvider();

    const vaults = await globalProvider
      .request({ method: 'vaults' })
      .catch((error) => {
        if (error.code === 5005) {
          dispatch(setWalletConnected(false));
          localStorage.removeItem('WalletConnected');
          handleShowLoading(false);
        }
        dispatch(setNotificationShow(true));
        dispatch(setNotification(error.message));
      });

    if (vaults) {
      const activeVault = vaults.find(
        (vault) => vault.name === activeAccount && vault.isActive
      );
      if (activeVault) {
        handleShowLoading(true);
        const accountNames = activeVault.accounts.map(
          (account) => account.accountName
        );
        const balances = await getCedeBalance(
          globalProvider,
          activeVault.id,
          accountNames
        ).catch((error) => {
          handleShowLoading(false);
          dispatch(setNotificationShow(true));
          dispatch(setNotification(error.message));
        });
        if (!balances || Object.keys(balances).length === 0) {
          handleShowLoading(false);
          dispatch(setNotificationShow(true));
          dispatch(setNotification('There is no assets in the vault'));
        } else {
          const allCoins = Object.entries(balances).flatMap(
            ([account, coinsData]) => {
              return Object.entries(coinsData)
                .filter((coin) => {
                  return coin[1].totalBalance !== null;
                })
                .map(([ticker, coinData]) => {
                  return { account, ticker, ...coinData };
                });
            }
          );
          const mergedAllCoins = mergeDuplicatedCoinsCede(allCoins);
          const sumTotalBalance = mergedAllCoins.reduce(
            (sum, item) => sum + item.refTotalBalance,
            0
          );
          const sumTotalTickers = mergedAllCoins
            .map((coin) => coin.ticker)
            .join(',');
          const assetsData = await getAssets(sumTotalTickers);
          const cedePrices = await getPrices(globalProvider);
          const resultArray = mergedAllCoins.map((coin) => {
            const matchingObject = assetsData.find(
              (item) => item.ticker === coin.ticker
            );
            return {
              ticker: coin.ticker,
              displayName: matchingObject
                ? matchingObject.displayName
                : coin.ticker,
              price: matchingObject
                ? matchingObject.price
                : cedePrices[coin.ticker]
                ? cedePrices[coin.ticker]
                : 'N/A',
              price24H: matchingObject ? matchingObject.price24H : 'N/A',
              quantity: coin.totalBalance,
              amount: coin.refTotalBalance,
              percentage: (coin.refTotalBalance / sumTotalBalance) * 100,
              tags: matchingObject ? matchingObject.tags : [],
              assetClass: matchingObject ? matchingObject.assetClass : 1,
              logo: matchingObject ? matchingObject.logo : null,
            };
          });
          saveCedePortfolio(
            activeAccount,
            sumTotalBalance,
            resultArray,
            myPortfolios
          );
        }
      } else {
        handleShowLoading(false);
      }
    }
  };

  const fetchAggregateData = useCallback(async (currentType) => {
    if (token && token !== 'EXPIRED') {
      handleShowLoading(true);
      const params = `${
        currentType === 'wallet'
          ? 'connected=true'
          : currentType === 'tracked'
          ? 'tracked=true'
          : 'tracked=false'
      }`;
      const data = await getPortfolioAggregate(token, params);
      if (data.status === 200) {
        resetAggregateData(data.data);
      } else {
        dispatch(setNotificationShow(true));
        dispatch(setNotification(data.error));
        handleShowLoading(false);
      }
    }
  }, []);

  const handleChangeType = (event) => {
    const newType = event.target.value;
    setTotalCoins([]);
    if (newType === 'wallet') {
      setAllData(myPortfolios.filter((el) => el.connected));
    } else {
      setAllData(
        myPortfolios.filter(
          (el) =>
            !el.connected && (newType === 'tracked' ? el.tracked : !el.tracked)
        )
      );
    }
    fetchAggregateData(newType);
    setType(newType);
  };

  const handleSelectDuration = (duration) => {
    setCurrentDuration(duration);

    if (duration.id === '24H') {
      setChartData(aggregateData.points24H);
      setActiveGraphData({
        change: aggregateData.change24H || 0,
        price: aggregateData.new_capital || 0,
        time: moment.utc(),
      });
    } else if (duration.id === '1M') {
      setChartData(aggregateData.points1M);
      setActiveGraphData({
        change: aggregateData.change1M || 0,
        price: aggregateData.new_capital || 0,
        time: moment.utc(),
      });
    } else if (duration.id === '1Y') {
      setChartData(aggregateData.points1Y);
      setActiveGraphData({
        change: aggregateData.change1Y || 0,
        price: aggregateData.new_capital || 0,
        time: moment.utc(),
      });
    } else {
      setChartData(aggregateData.points_portfolio_age);
      setActiveGraphData({
        change: aggregateData.change_portfolio_age || 0,
        price: aggregateData.new_capital || 0,
        time: moment.utc(),
      });
    }
  };

  const createdCedePortfolio = async (portfolio) => {
    if (token) {
      handleShowLoading(true);
      const portfolioCreated = await createPortfolio(portfolio, token);
      if (portfolioCreated.status === 200 || portfolioCreated.status === 201) {
        dispatch(setNotificationShow(false));
        dispatch(setUnfinishedPortfolio(false));
        handleFetchPortfolio();
        fetchAggregateData('wallet');
      } else {
        dispatch(setNotificationShow(true));
        dispatch(setNotification(portfolioCreated.message));
        handleShowLoading(false);
      }
    } else {
      handleShowLoading(false);
      dispatch(setUnfinishedPortfolio(portfolio));
      navigate('/login');
    }
  };

  const updateCedePortfolio = async (portfolio, portfolioToUpdate) => {
    if (token) {
      handleShowLoading(true);
      if (!checkArraysEqual(portfolioToUpdate.assets, portfolio.coins)) {
        portfolioToUpdate = {
          ...portfolioToUpdate,
          assets: portfolio.coins,
        };

        const portfolioUpdated = await updatePortfolio(
          portfolioToUpdate,
          token
        );

        if (
          portfolioUpdated.status === 200 ||
          portfolioUpdated.status === 201
        ) {
          dispatch(setNotificationShow(false));
          dispatch(setUnfinishedPortfolio(false));
          handleFetchPortfolio();
          fetchAggregateData('wallet');
        } else {
          dispatch(setNotificationShow(true));
          dispatch(setNotification(portfolioUpdated.error));
          handleShowLoading(false);
        }
      }
    } else {
      navigate('/login');
    }
  };

  const saveCedePortfolio = useCallback(
    async (account, capital, coins, portfolios) => {
      if (coins.length > 0) {
        const portfolio = {
          title: `Cede.store - ${account}`,
          category: [0],
          coins,
          capital,
          live: false,
          tracked: true,
          connected: true,
          manual: false,
          currency,
        };

        const findConnected = portfolios.find(
          (el) => el.name === `Cede.store - ${account}`
        );

        if (findConnected) {
          await updateCedePortfolio(portfolio, findConnected);
        } else {
          await createdCedePortfolio(portfolio);
        }
      }
    },
    []
  );

  useEffect(() => {
    setAllData(myPortfolios.filter((el) => !el.connected && el.tracked));
    fetchAggregateData('tracked');
  }, []);

  useEffect(() => {
    if (activeAccount && connected) {
      getCedeVaults();
      setType('wallet');
    }
    if (!connected && type === 'wallet') {
      setTotalCoins([]);
      setType('tracked');
      setAllData(myPortfolios.filter((el) => !el.connected && el.tracked));
      fetchAggregateData('tracked');
    }
  }, [activeAccount, connected]);

  useEffect(() => {
    const newTotalValue = allData.reduce((accumulator, data) => {
      return (
        accumulator +
        data.assets.reduce((acc, asset) => {
          return acc + parseNumber(asset.new_amount || asset.amount);
        }, 0)
      );
    }, 0);

    const mergedCoins = [].concat(...allData.map((item) => item.assets));
    mergeDuplicatedCoins(mergedCoins, newTotalValue);
  }, [allData]);

  useEffect(() => {
    if (type === 'wallet') {
      setAllData(myPortfolios.filter((el) => el.connected));
    } else {
      setAllData(
        myPortfolios.filter(
          (el) =>
            !el.connected && (type === 'tracked' ? el.tracked : !el.tracked)
        )
      );
    }
  }, [myPortfolios]);

  useEffect(() => {
    const newCumulation = parseFloat(
      ((activeGraphData.price - aggregateData.capital) /
        aggregateData.capital) *
        100
    );
    setCumulation(newCumulation);
  }, [activeGraphData]);

  const handleActiveData = (data) => {
    setActiveGraphData(data);
  };

  const handleMouseLeave = () => {
    setActiveGraphData({
      change:
        (currentDuration.id === '24H'
          ? aggregateData.change24H
          : currentDuration.id === '1M'
          ? aggregateData.change1M
          : currentDuration.id === '1Y'
          ? aggregateData.change1Y
          : aggregateData.change_portfolio_age) || 0,
      price: aggregateData.new_capital || 0,
      time: moment.utc(),
    });
  };

  return (
    <CAccordion
      id={id}
      title={title}
      handleAccordion={handleAccordion}
      expand={expanded}
    >
      <Box sx={{ position: 'relative', minHeight: 80 }}>
        <Grid container columnSpacing={2}>
          <Grid item xs={12} sm={12} md={6}>
            <StyledSelect
              labelId="portfolio-mode-selection-label"
              id="portfolio-mode-selection"
              value={type}
              displayEmpty
              onChange={handleChangeType}
            >
              <MenuItem value="tracked">Tracked</MenuItem>
              <MenuItem value="planned">Planned</MenuItem>
              <MenuItem value="wallet">Connected</MenuItem>
            </StyledSelect>
            <Box
              sx={{
                display: 'flex',
                width: '100%',
                height: '100%',
                minHeight: 50,
              }}
            >
              {showLoading ? (
                <Skeleton
                  variant="circular"
                  width={180}
                  height={180}
                  sx={{ margin: 'auto' }}
                />
              ) : totalCoins.length > 0 ? (
                <Box sx={{ width: '100%', height: '100%', minHeight: 50 }}>
                  <PieChartEl data={totalCoins} />
                </Box>
              ) : null}
            </Box>
          </Grid>
          <Grid item xs={12} sm={12} md={6}>
            {showLoading ? (
              <Skeleton variant="rounded" width="100%" height={300} />
            ) : totalCoins.length > 0 && type !== 'planned' ? (
              chartData && chartData.length > 0 && cumulation > -100 ? (
                <>
                  <Grid container columnSpacing={2}>
                    <Grid item xs={12} sm={6} md={8}>
                      <Typography color="white" variant="h5" fontWeight={500}>
                        $
                        {activeGraphData.price.toLocaleString(undefined, {
                          minimumFractionDigits: 2,
                          maximumFractionDigits: 2,
                        })}
                        <Typography
                          component="span"
                          color={theme.palette.white}
                          variant="caption"
                        >
                          {' '}
                          (
                          {parseChartDateLocale(
                            activeGraphData.time,
                            user?.timezone,
                            currentDuration.id === '24H' ? true : false
                          )}
                          )
                        </Typography>
                      </Typography>
                      <Stack
                        flexDirection="row"
                        alignItems="center"
                        columnGap={0.5}
                        my={1}
                      >
                        <Typography color="white" fontWeight={500}>
                          Total return
                        </Typography>
                        <StyledPriceChip
                          label={`${`${
                            cumulation > 0 ? '+' : ''
                          }${cumulation.toFixed(2)}%`}`}
                          color={cumulation >= 0 ? 'success' : 'warning'}
                          size="small"
                          sx={{
                            color:
                              cumulation >= 0
                                ? theme.palette.primary.main
                                : theme.palette.white,
                          }}
                        />
                      </Stack>
                      {currentDuration.id !== 'tracking_period' ? (
                        <Stack
                          flexDirection="row"
                          alignItems="center"
                          columnGap={0.5}
                          my={1}
                        >
                          <Typography color="white" fontWeight={500}>
                            {currentDuration.label} return
                          </Typography>
                          <StyledPriceChip
                            label={`${`${
                              activeGraphData.change > 0 ? '+' : ''
                            }${activeGraphData.change.toFixed(2)}%`}`}
                            color={
                              activeGraphData.change >= 0
                                ? 'success'
                                : 'warning'
                            }
                            size="small"
                            sx={{
                              color:
                                activeGraphData.change >= 0
                                  ? theme.palette.primary.main
                                  : theme.palette.white,
                            }}
                          />
                        </Stack>
                      ) : null}
                    </Grid>
                    <Grid item xs={12} sm={6} md={4}>
                      <Grid
                        container
                        direction="column"
                        justifyContent="center"
                        alignItems={isMobile ? 'flex-start' : 'flex-end'}
                        sx={{ height: '100%' }}
                      >
                        <Typography
                          color="white"
                          fontWeight={500}
                          sx={{ lineHeight: '35px' }}
                        >
                          Risk score:
                          <sup>
                            <Tooltip
                              title={
                                <>
                                  {tooltipDescription}{' '}
                                  <Link
                                    href="https://divercefi.com/portfolio-risk-score"
                                    target="_blank"
                                    style={{
                                      color: 'inherit',
                                      textDecoration: 'underline',
                                    }}
                                  >
                                    methodology
                                  </Link>
                                  .
                                </>
                              }
                              sx={{ color: theme.palette.lightGrey3 }}
                            >
                              <InfoIcon fontSize="50%" />
                            </Tooltip>
                          </sup>{' '}
                          <ColorIndicator
                            number={Math.round(aggregateData.riskScore)}
                          />
                        </Typography>
                      </Grid>
                    </Grid>
                  </Grid>
                  <Stack
                    direction="row"
                    alignItems="center"
                    justifyContent="end"
                    gap={1}
                    mt={1}
                    mb={2}
                  >
                    {durationList.map((duration) => (
                      <StyledButton
                        variant="contained"
                        key={duration.id}
                        className={`${
                          duration.id === currentDuration.id ? 'active' : ''
                        } ${
                          duration.id === 'tracking_period'
                            ? 'long_content'
                            : ''
                        }`}
                        onClick={() => {
                          handleSelectDuration(duration);
                        }}
                      >
                        {duration.label}
                      </StyledButton>
                    ))}
                  </Stack>
                  <AreaChartEl
                    id="aggregate"
                    data={chartData}
                    duration={currentDuration.id}
                    height={250}
                    color="white"
                    timezone={user?.timezone}
                    periodStartPrice={chartData[0].price}
                    portfolioEntryPrice={portfolioEntryPrice}
                    handleActiveData={handleActiveData}
                    handleMouseLeave={handleMouseLeave}
                  />
                </>
              ) : (
                <Stack
                  alignItems="center"
                  justifyContent="center"
                  gap={1}
                  sx={{ height: 250 }}
                >
                  <Typography color="white">
                    Reload the page to visualize graph data
                  </Typography>
                  <MButton sm="true" purple="true" onClick={handleReload}>
                    Reload
                  </MButton>
                </Stack>
              )
            ) : null}
          </Grid>
        </Grid>
        <Box mt={1}>
          {showLoading ? (
            <PortfolioAssetsTable
              isLoading
              tableData={skeletonAggregateTableData}
            />
          ) : totalCoins.length > 0 ? (
            <PortfolioAssetsTable isLoading={false} tableData={totalCoins} />
          ) : null}
        </Box>
        {totalCoins.length === 0 && !showLoading ? (
          <CenterBox>
            <Typography color="white" sx={{ fontSize: 24, fontWeight: 500 }}>
              No{' '}
              {type === 'tracked'
                ? 'tracked'
                : type === 'planned'
                ? 'planned'
                : 'connected'}{' '}
              portfolio yet
            </Typography>
          </CenterBox>
        ) : null}
      </Box>
    </CAccordion>
  );
};

export default AggregatePortfolio;
