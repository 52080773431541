import {
  createStore as reduxCreateStore,
  applyMiddleware,
  combineReducers
} from 'redux';
import thunk from 'redux-thunk';
import { composeWithDevTools } from 'redux-devtools-extension';
import websocketMiddleware from './socketMiddleware';

// import aboutReducer from '../reducers/aboutReducer';
// import valuesReducer from '../reducers/valuesReducer';
// import countryReducer from '../reducers/countryReducer';
import currencyReducer from '../reducers/currencyReducer';
import coinsReducer from '../reducers/coinsReducer';
import assetsReducer from '../reducers/assetsReducer';
// import exchangesReducer from '../reducers/exchangesReducer';
import walletsReducer from '../reducers/walletsReducer';
// import extrasReducer from '../reducers/extrasReducer';
// import dashboardReducer from '../reducers/dashboardReducer';
// import uiReducer from '../reducers/uiReducer';
// import stakingReducer from '../reducers/stakingReducer';
// import portofolioReducer from '../reducers/portofolioReducer';
import portofolioReducer from '../reducers/portfolioReducer';
import portfolioExplorerReducer from '../reducers/portfolioExplorerReducer';
import userReducer from '../reducers/userReducer';
import globalReducer from '../reducers/globalReducer';
import filterReducer from '../reducers/filterReducer';

const reducers = {
  // about: aboutReducer,
  // values: valuesReducer,
  // country: countryReducer,
  currency: currencyReducer,
  coins: coinsReducer,
  assets: assetsReducer,
  portfolio: portofolioReducer,
  portfolioExplorer: portfolioExplorerReducer,
  user: userReducer,
  global: globalReducer,
  filter: filterReducer,
  // exchanges: exchangesReducer,
  wallets: walletsReducer
  // extras: extrasReducer,
  // dashboard: dashboardReducer,
  // portofolio: portofolioReducer,
  // ui: uiReducer,
  // staking: stakingReducer,
};

const reducer = combineReducers(reducers);

const createStore = () => reduxCreateStore(reducer, composeWithDevTools(applyMiddleware(thunk, websocketMiddleware)));
export default createStore;
