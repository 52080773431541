import {
  CONNECT_SOCKET,
  DISCONNECT_SOCKET,
  SUBSCRIBE_ASSETS,
  updateAssets,
  socketConnected,
  socketDisconnected,
} from '../reducers/assetsReducer';

let socket = null;
let reconnectTimeout = null;

const websocketMiddleware = (store) => (next) => (action) => {
  switch (action.type) {
    case CONNECT_SOCKET:
      if (socket) {
        // console.log('WebSocket already connected or connecting');
        return;
      }

      socket = new WebSocket(process.env.REACT_APP_BE_API_SOCKET_URI);

      socket.onopen = () => {
        // console.log('WebSocket connection established');
        store.dispatch(socketConnected());

        const state = store.getState();
        const { tickers } = state.assets.assets;

        if (tickers && tickers.length > 0) {
          tickers.forEach((ticker) => {
            socket.send(
              JSON.stringify({ action: 'subscribe', symbol: ticker })
            );
          });
        }
      };

      socket.onmessage = (event) => {
        const data = JSON.parse(event.data);
        if (data.type === 'ticker') {
          store.dispatch(updateAssets(data));
        }
      };

      socket.onclose = () => {
        // console.log('WebSocket connection closed');
        store.dispatch(socketDisconnected());
        reconnectTimeout = setTimeout(() => {
          store.dispatch({ type: CONNECT_SOCKET });
        }, 5000);
      };

      socket.onerror = (error) => {
        console.error('WebSocket error:', error);
      };
      break;

    case DISCONNECT_SOCKET:
      if (socket) {
        socket.close();
      }
      clearTimeout(reconnectTimeout);
      socket = null;
      break;

    case SUBSCRIBE_ASSETS:
      if (socket && socket.readyState === WebSocket.OPEN) {
        action.payload.forEach((ticker) => {
          socket.send(JSON.stringify({ action: 'subscribe', symbol: ticker }));
        });
      }
      break;

    default:
      return next(action);
  }
};

export default websocketMiddleware;
