import React, { useState, useEffect, useMemo, useCallback } from 'react';
import { useNavigate } from 'react-router-dom';
import { useSelector, useDispatch } from 'react-redux';
import {
  Stack,
  Grid,
  InputAdornment,
  Box,
  FormControlLabel,
  Switch,
  DialogTitle,
  useMediaQuery,
} from '@mui/material';
import { useTheme } from '@mui/material/styles';
import { CheckCircle as CheckCircleIcon } from '@mui/icons-material';
import CDialog from '../../../CDialog';
import MButton from '../../../../Common/MButton';
import PortfolioSelect from '../../PortfolioSelect';
import PortfolioTable from './PortfolioTable';
import PieChartEl from '../../../../PieChartEl';
import {
  addPortfolioCoin,
  addPortfolioCoins,
  removePortfolioCoins,
  resetAllocation,
  updateAllocation,
} from '../../../../../reducers/portfolioReducer';
import {
  setNotificationShow,
  setNotification,
  setUnfinishedPortfolio,
} from '../../../../../reducers/globalReducer';
import {
  StyledTypography,
  StyledTextField,
  StyledMessage,
  StyledToggleButtonGroup,
  StyledToggleButton,
  CenterBox,
} from '../styles';
import { StyledNumberField } from '../../PortfolioTable/components/PortfolioTableItem/styles';
import { parseNumber, parseOutput } from '../../../../../utils/parseNumber';
import {
  createPortfolio,
  getPortfolioList,
  updatePortfolio,
} from '../../../../../lib/assetsApi';
import { loadPortfolios } from '../../../../../reducers/portfolioExplorerReducer';
import { getAuthToken } from '../../../../../lib/auth';
import { handleKeyDownNumber } from '../../../../../utils/keyEvent';

const PortfolioModal = ({
  open,
  onClose,
  handleShowLoading,
  unfinishedPortfolio,
}) => {
  const theme = useTheme();
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const isXMobile = useMediaQuery((th) => th.breakpoints.down('sm'));
  const isMobile = useMediaQuery((th) => th.breakpoints.down('md'));
  const assetsData = useSelector((state) => state.portfolio.list);
  const portfolios = useSelector((state) => state.portfolioExplorer);
  const token = getAuthToken();
  const focusedInput = useSelector((state) => state.global.currentFocus);
  const focusedInputData = useSelector(
    (state) => state.global.currentFocusData
  );
  const currency = useSelector((state) => state.currency);
  const currencySymbol = useMemo(
    () => (currency === 'USD' ? '$' : '€'),
    [currency]
  );
  const [isLive, setIsLive] = useState(true);
  const [isTrack, setIsTrack] = useState(true);
  const [isExistingPortfolio, setIsExistingPortfolio] = useState(false);
  const [selectedPortfolio, setSelectedPortfolio] = useState('');
  const [showCapital, setShowCapital] = useState(false);
  const [capitalInput, setCapitalInput] = useState(0);
  const [capitalAmount, setCapitalAmount] = useState(0);
  const [cashAmount, setCashAmount] = useState(0);
  const [portfolioData, setPortfolioData] = useState([]);
  const [title, setTitle] = useState('');
  const [error, setError] = useState(false);
  const [totalPercentage, setTotalPercentage] = useState(0);
  const [totalValue, setTotalValue] = useState(0);
  const [isSubmitting, setIsSubmitting] = useState(false);
  const [showExistingPortfolios, setShowExistingPortfolios] = useState(false);
  const [changedCashFlag, setChangedCashFlag] = useState(false);

  const fetchData = useCallback(async () => {
    const params = 'limit=10';
    if (token && token !== 'EXPIRED') {
      const data = await getPortfolioList(token, params);
      if (data.status === 200) {
        dispatch(loadPortfolios(data.data));
        setShowExistingPortfolios(data.data?.length ? true : false);
      } else {
        setShowExistingPortfolios(false);
        dispatch(setNotificationShow(true));
        dispatch(setNotification(data.error));
      }
    } else {
      setShowExistingPortfolios(false);
    }
  }, []);

  useEffect(() => {
    fetchData();
  }, []);

  useEffect(() => {
    // calculate current total percentage
    const newAssetsPercentage = assetsData
      // .filter((el) => el.selected)
      .map((el) => el.percentage)
      .reduce((prev, curr) => {
        return parseNumber(prev) + parseNumber(curr);
      }, 0);
    setTotalPercentage(newAssetsPercentage);

    const filteredData = (assets, applyFilter) => {
      if (applyFilter) {
        return assets.filter((el) => el.id !== currency);
      }
      return assets;
    };
    const isExistingVirtual = !isLive && isExistingPortfolio;

    const newTotalValue = filteredData(assetsData, isExistingVirtual)
      .map((el) => el.amount)
      .reduce((prev, curr) => {
        return parseNumber(prev) + parseNumber(curr);
      }, 0);
    setTotalValue(newTotalValue);

    if (isLive) {
      setCapitalAmount(newTotalValue);
    }

    // calculate the value and percentage of cash
    if (newAssetsPercentage > 0 && capitalAmount > 0) {
      setCapitalInput(
        parseFloat((capitalAmount * (100 - newAssetsPercentage)) / 100)
      );
    }

    // merge duplicated datas
    const dataToUpdate = assetsData.reduce((accumulator, el) => {
      const existingIndex = accumulator.findIndex((item) => item.id === el.id);
      if (existingIndex !== -1) {
        accumulator[existingIndex].percentage += parseNumber(el.percentage);
        accumulator[existingIndex].quantity += parseNumber(el.quantity);
        accumulator[existingIndex].amount += parseNumber(el.amount);
      } else {
        accumulator.push({
          id: el.id,
          displayName: el.displayName,
          assetClass: el.assetClass,
          tags: el.tags,
          price: el.price,
          percentage: parseNumber(el.percentage),
          quantity: parseNumber(el.quantity),
          amount: parseNumber(el.amount),
          ticker: el.ticker,
          logo: el.logo,
        });
      }
      return accumulator;
    }, []);
    setPortfolioData(dataToUpdate);

    if (!(!isLive && !isExistingPortfolio)) {
      const cashIndex = assetsData.findIndex((el) => el.id === currency);
      if (cashIndex !== -1) {
        setCashAmount(assetsData[cashIndex].amount);
      } else {
        setCashAmount(0);
      }
    } else {
      // eslint-disable-next-line no-lonely-if
      if (changedCashFlag) {
        setCashAmount(parseNumber(capitalInput));
        setChangedCashFlag(false);
      } else {
        setCashAmount((capitalAmount * (100 - newAssetsPercentage)) / 100);
      }
    }

    if (!isExistingPortfolio && !isLive) {
      // add cash if totalpercentage < 100 after setup capital
      if (newAssetsPercentage < 100 && capitalAmount > 0) {
        setPortfolioData((prevItems) => [
          ...prevItems,
          {
            id: currency,
            displayName: currency,
            ticker: currency,
            assetClass: 1,
            tags: [],
            percentage: parseFloat(100 - newAssetsPercentage),
            price: 1,
            quantity: parseFloat(
              (capitalAmount * (100 - newAssetsPercentage)) / 100
            ),
            amount: parseFloat(
              (capitalAmount * (100 - newAssetsPercentage)) / 100
            ),
            selected: true,
          },
        ]);
      } else if (newAssetsPercentage > 0 && capitalAmount === 0) {
        // add unallocated if capital is not setup
        setPortfolioData((prevItems) => [
          ...prevItems,
          {
            id: 'unallocated',
            displayName: 'Unallocated',
            assetClass: 4,
            tags: [],
            percentage: parseFloat(100 - newAssetsPercentage),
            price: 0,
            quantity: 0,
            amount: 0,
            ticker: 'unallocated',
          },
        ]);
      }
    }
  }, [assetsData]);

  useEffect(() => {
    if (isLive) {
      setIsTrack(true);
    }
  }, [isLive]);

  useEffect(() => {
    if (!isLive && !isExistingPortfolio) {
      if (isTrack) {
        setCapitalAmount(0);
        setCapitalInput(0);
        setShowCapital(false);
      } else {
        setCapitalAmount(0);
        setCapitalInput(0);
        setShowCapital(false);
      }
    }
  }, [isTrack, isLive]);

  useEffect(() => {
    setIsTrack(true);
    if (isExistingPortfolio) setIsLive(null);
    else setIsLive(true);
  }, [isExistingPortfolio]);

  useEffect(() => {
    // if portfolio selected, redistribute assets for existing assets.
    // redistributeAssets(totalPercentage);
  }, [selectedPortfolio]);

  useEffect(() => {
    // if amount and quantity editing, redistribute percentage if live portfolio
    if (
      isLive &&
      (focusedInput.includes('amount') || focusedInput.includes('quantity'))
    ) {
      redistributePercentage();
    }
  }, [totalValue]);

  useEffect(() => {
    if (focusedInput) {
      if (
        focusedInput.includes('weight') &&
        parseNumber(focusedInputData.newPercentage) < 100
      ) {
        redistributeQuantityValue(focusedInputData);
      }
      // } else if (
      //   focusedInput.includes('amount') ||
      //   focusedInput.includes('quantity')
      // ) {
      //   redistributeAll(focusedInputData);
      // }
    }
  }, [focusedInput]);

  useEffect(() => {
    if (unfinishedPortfolio) {
      setTitle(unfinishedPortfolio.title);
      setIsLive(unfinishedPortfolio.live);
      setIsTrack(unfinishedPortfolio.tracked);
      setCapitalAmount(parseFloat(unfinishedPortfolio.capital));
    }
  }, [unfinishedPortfolio]);

  // const redistributeAll = (data) => {
  //   const value = isControl ? capitalAmount : totalValue;
  //   assetsData.map((el) => {
  //     if (el.uuid === data.uuid) {
  //       const newPercentage = parseNumber((el.amount / value) * 100);
  //       const focusData = {
  //         uuid: data.uuid,
  //         newPercentage,
  //         oldPercentage: data.oldPercentage,
  //       };
  //       redistributeQuantityValue(focusData);
  //       return dispatch(
  //         updateAllocation(el.uuid, newPercentage, el.quantity, el.amount)
  //       );
  //     }
  //     return 0;
  //   });
  // };

  const redistributePercentage = () => {
    const value = totalValue;
    assetsData.map((el) => {
      const newPercentage = parseNumber((el.amount / value) * 100);
      return dispatch(
        updateAllocation(el.uuid, newPercentage, el.quantity, el.amount)
      );
    });
  };

  const redistributeQuantityValue = (data) => {
    const value = totalValue;
    assetsData.map((el) => {
      /** set percentage, amount, quantity to current focused one */
      if (el.uuid === data.uuid) {
        const newAmount = parseNumber(
          (value * parseFloat(el.percentage)) / 100
        );
        const newQuantity = parseNumber(newAmount / el.price);
        return dispatch(
          updateAllocation(el.uuid, el.percentage, newQuantity, newAmount)
        );
      }
      /** redistribute other assets based on current editing */
      const newPercentage = parseFloat(
        ((100 - parseNumber(data.newPercentage)) *
          (100 / (100 - parseNumber(data.oldPercentage))) *
          el.percentage) /
          100
      );
      // const newAmount = parseFloat((value * newPercentage) / 100);
      // const newQuantity = parseFloat(newAmount / el.price);
      return dispatch(
        updateAllocation(el.uuid, newPercentage, el.quantity, el.amount)
      );
    });
  };

  /** redistribute assets from existing portfolio */
  const redistributeAssets = (availablePercentage) => {
    assetsData
      .filter((el) => !el.selected)
      .map((el) => {
        const newPercentage = parseFloat(
          ((100 - availablePercentage) * el.oldPercentage) / 100
        );
        const newAmount = parseFloat((capitalAmount * newPercentage) / 100);
        const newQuantity = parseFloat(newAmount / el.price);
        return dispatch(
          updateAllocation(el.uuid, newPercentage, newQuantity, newAmount)
        );
      });
  };

  const handleChangeTrackCondition = (event, newValue) => {
    if (newValue !== null) {
      setIsLive(newValue);
      dispatch(resetAllocation());
    }
  };

  const handleChangeImportCondition = (event, newValue) => {
    dispatch(resetAllocation());
    if (newValue !== null) {
      assetsData.map(
        (item) => !item.selected && dispatch(removePortfolioCoins(item.uuid))
      );
      setIsExistingPortfolio(newValue);
    } else setSelectedPortfolio('');
  };

  const handleChangeCapital = (event) => {
    handleCheckValid(event);
    setCapitalInput(event.target.value);
  };

  const handleCheckValid = (event) => {
    if (!event.target.value) {
      dispatch(setNotificationShow(true));
      dispatch(setNotification('Please input valid number'));
    }
  };

  const handleConfirmCapital = () => {
    if (!(!isLive && !isExistingPortfolio)) {
      const updatedCapitalAmount =
        capitalAmount + parseNumber(capitalInput) - cashAmount;
      setCapitalAmount(updatedCapitalAmount);
      const updatedPercentage = parseFloat(
        (parseNumber(capitalInput) / updatedCapitalAmount) * 100
      );
      /* redistribute percentage for cash */
      const cashIndex = assetsData.findIndex((el) => el.id === currency);
      if (cashIndex !== -1) {
        updateCash(cashIndex, updatedPercentage, parseNumber(capitalInput));
      } else {
        addCash(updatedPercentage, parseNumber(capitalInput));
      }

      /* redistribute percentage for old assets except cash */
      assetsData
        .filter((el) => el.id !== currency && !el.selected)
        .map((el) => {
          const newPercentage = parseNumber(
            (el.amount / updatedCapitalAmount) * 100
          );
          return dispatch(
            updateAllocation(el.uuid, newPercentage, el.quantity, el.amount)
          );
        });
      setShowCapital(false);
    } else {
      setChangedCashFlag(true);
      const updatedCapitalAmount =
        capitalAmount + parseNumber(capitalInput) - cashAmount;
      setCapitalAmount(updatedCapitalAmount);
      setCashAmount(parseNumber(capitalInput));
      setCapitalInput(parseNumber(capitalInput));
      setShowCapital(false);
      assetsData.map((el) => {
        const newPercentage = parseNumber(
          (el.amount / updatedCapitalAmount) * 100
        );

        return dispatch(
          updateAllocation(
            el.uuid,
            el.amount ? newPercentage : el.percentage,
            el.quantity,
            el.amount
          )
        );
      });
    }
    // }
  };

  const handleKeyDownCapital = (event) => {
    handleKeyDownNumber(event);
    if (event.key === 'Enter') {
      handleConfirmCapital();
    }
  };

  const handleSelectPortfolio = (id, tracked, live, amount) => {
    setSelectedPortfolio(id);
    setIsLive(live);
    setIsTrack(tracked);
    setCapitalInput(amount);
    setCapitalAmount(amount);
  };

  const handleSubmit = async (event) => {
    event.preventDefault();

    if (isExistingPortfolio) {
      let portfolioToUpdate = portfolios.myPortfolio.find(
        (el) => el.id === selectedPortfolio
      );
      portfolioToUpdate = {
        ...portfolioToUpdate,
        assets: portfolioData.filter((el) => el.percentage),
        capital: capitalAmount,
      };
      setIsSubmitting(true);
      if (token) {
        handleShowLoading(true);
        const portfolioUpdated = await updatePortfolio(
          portfolioToUpdate,
          token
        );
        if (
          portfolioUpdated.status === 200 ||
          portfolioUpdated.status === 201
        ) {
          dispatch(setNotificationShow(false));
          setIsSubmitting(true);
        } else {
          dispatch(setNotificationShow(true));
          dispatch(setNotification(portfolioUpdated.error));
        }
        handleShowLoading(false);
      } else {
        navigate('/login');
      }
    } else if (!isExistingPortfolio) {
      if (title.trim() === '') {
        setError(true);
      } else if (totalPercentage < 99.99 && capitalAmount === 0) {
        dispatch(setNotificationShow(true));
        dispatch(setNotification('User can not be able to have unallocated'));
      } else {
        const coins = portfolioData
          .filter((el) => el.id !== 'unallocated' && el.percentage)
          .map(
            ({
              id,
              displayName,
              assetClass,
              tags,
              price,
              percentage,
              quantity,
              amount,
              ticker,
              logo,
            }) => ({
              id,
              displayName,
              assetClass,
              tags,
              price,
              percentage,
              quantity,
              amount,
              ticker,
              logo,
            })
          );
        const portfolio = {
          title,
          category: [0],
          coins,
          capital: capitalAmount,
          live: isLive,
          tracked: isTrack,
          currency,
        };

        if (token) {
          handleShowLoading(true);
          const portfolioCreated = await createPortfolio(portfolio, token);

          if (
            portfolioCreated.status === 200 ||
            portfolioCreated.status === 201
          ) {
            dispatch(setNotificationShow(false));
            setIsSubmitting(true);
            // handleClose();
            // navigate('/overview');
            dispatch(setUnfinishedPortfolio(false));
          } else {
            dispatch(setNotificationShow(true));
            dispatch(setNotification(portfolioCreated.message));
          }
          handleShowLoading(false);
        } else {
          handleShowLoading(false);
          dispatch(setUnfinishedPortfolio(portfolio));
          navigate('/login');
        }
      }
    }
  };

  const handleChangeName = (event) => {
    setTitle(event.target.value);
    setError(false);
  };

  const handleKeyDownName = (event) => {
    if (event.key === 'Enter') {
      event.preventDefault();
    }
  };

  const handleReset = () => {
    dispatch(resetAllocation());
    if (isExistingPortfolio) {
      const selectedPortfolioRedux = portfolios.myPortfolio.find(
        (value) => value.id === selectedPortfolio
      );

      if (selectedPortfolioRedux) {
        assetsData.map(
          (item) => !item.selected && dispatch(removePortfolioCoins(item.uuid))
        );

        const timestamp = new Date().getTime();
        Promise.all(
          selectedPortfolioRedux.assets.map((el) => {
            const updatedCoin = {
              ...el,
              amount: el.new_amount || el.amount,
              percentage: el.new_percentage || el.percentage,
              uuid: el.id + timestamp,
              oldPercentage: el.percentage,
            };
            return dispatch(addPortfolioCoin([updatedCoin]));
          })
        );
        setCapitalInput(selectedPortfolioRedux.new_capital);
        setCapitalAmount(selectedPortfolioRedux.new_capital);
      }
    } else {
      setCapitalInput(0);
      setCapitalAmount(0);
    }
  };

  const handleClose = () => {
    setError(false);
    setTitle('');
    setCapitalInput(0);
    setCapitalAmount(0);
    setCashAmount(0);
    setTotalValue(0);
    setShowCapital(false);
    setIsLive(true);
    setIsTrack(true);
    setIsExistingPortfolio(false);
    dispatch(addPortfolioCoins([]));
    dispatch(setUnfinishedPortfolio(false));
    if (isSubmitting) {
      navigate('/overview');
    }
    setIsSubmitting(false);
    onClose();
  };

  const handleTrack = (event) => {
    setIsTrack(event.target.checked);
  };

  const updateCash = (index, percentage, amount) => {
    dispatch(
      updateAllocation(assetsData[index].uuid, percentage, amount, amount)
    );
  };

  const addCash = (percentage, amount) => {
    dispatch(
      addPortfolioCoin([
        {
          id: currency,
          displayName: currency,
          ticker: currency,
          uuid: currency,
          assetClass: 1,
          tags: [],
          percentage,
          price: 1,
          quantity: amount,
          amount,
          selected: true,
        },
      ])
    );
  };

  return (
    <CDialog
      open={open}
      width="100%"
      height="100%"
      bgcolor={isSubmitting ? 'purple' : 'transparent'}
      onClose={handleClose}
    >
      <Grid
        container
        columnSpacing={isMobile ? 0 : 2}
        rowSpacing={isMobile ? 2 : 0}
        sx={{ opacity: isSubmitting ? 0 : 1 }}
      >
        <Grid item xs={12} sm={12} md={7}>
          <Box
            sx={{
              height: '100%',
              padding: 2,
              background: 'white',
              borderRadius: 2,
            }}
          >
            <DialogTitle sx={{ paddingX: 0, paddingTop: isXMobile ? 2 : 0 }}>
              {isSubmitting ? '' : 'Add assets to a Portfolio'}
            </DialogTitle>
            <Stack spacing={3} component="form" onSubmit={handleSubmit}>
              <>
                <StyledToggleButtonGroup
                  color="primary"
                  value={isExistingPortfolio}
                  exclusive
                  onChange={handleChangeImportCondition}
                  aria-label="Portfolio"
                  sx={{
                    flexDirection: isXMobile ? 'column' : 'row',
                    width: '100%',
                    maxWidth: isXMobile ? '100%' : 'calc(100% - 166px)',
                  }}
                >
                  <StyledToggleButton value={false}>
                    New Portfolio on app.Divercefi
                  </StyledToggleButton>
                  <StyledToggleButton
                    value
                    disabled={
                      !token || token === 'EXPIRED' || !showExistingPortfolios
                    }
                  >
                    Existing Portfolio on the app.Divercefi
                    {/* <Tooltip title="Add asset(s) to existing Divercefi Portfolio">
                      <Info />
                    </Tooltip> */}
                  </StyledToggleButton>
                </StyledToggleButtonGroup>
                {isExistingPortfolio && (
                  <PortfolioSelect
                    data={[
                      ...portfolios.myPortfolio.filter(
                        (portfolio) => !portfolio.connected
                      ),
                    ]}
                    onSelect={handleSelectPortfolio}
                  />
                )}
                <Stack direction={isXMobile ? 'column' : 'row'} spacing={2}>
                  <StyledToggleButtonGroup
                    color="primary"
                    value={isLive}
                    exclusive
                    onChange={handleChangeTrackCondition}
                    aria-label="Portfolio"
                    sx={{
                      flexDirection: isXMobile ? 'column' : 'row',
                      width: '100%',
                    }}
                    disabled={isExistingPortfolio}
                  >
                    <StyledToggleButton value>
                      <b>Live Portfolio:</b> I already own the assets in real
                      life
                    </StyledToggleButton>
                    <StyledToggleButton value={false}>
                      <b>Virtual Portfolio:</b> I don&apos;t own the assets yet
                    </StyledToggleButton>
                  </StyledToggleButtonGroup>
                  <FormControlLabel
                    control={
                      <Switch checked={isTrack} onChange={handleTrack} />
                    }
                    label="Track Portfolio"
                    sx={{
                      width: '100%',
                      maxWidth: isXMobile ? '100%' : 150,
                      visibility:
                        isLive || isExistingPortfolio ? 'hidden' : 'visible',
                    }}
                  />
                </Stack>
                {!isExistingPortfolio && (
                  <StyledTextField
                    fullWidth
                    label="Portfolio Name"
                    onChange={handleChangeName}
                    value={title}
                    onKeyDown={handleKeyDownName}
                    error={error}
                    helperText={error ? 'Portfolio name cannot be empty' : ''}
                  />
                )}
              </>
              <Stack rowGap={1}>
                <Stack
                  direction={isXMobile ? 'column' : 'row'}
                  alignItems="center"
                  justifyContent={
                    showCapital || capitalAmount > 0
                      ? 'space-between'
                      : 'flex-start'
                  }
                  gap={3}
                >
                  <Box>
                    <Stack
                      direction="row"
                      alignItems={isXMobile ? 'flex-start' : 'center'}
                      justifyContent="flex-start"
                      gap={1}
                    >
                      <StyledTypography sx={{ whiteSpace: 'nowrap' }}>
                        {isLive ? 'Total Estimated Value' : 'Invested Capital'}:{' '}
                        {currencySymbol}
                        {
                          // !isControl && !isExistingPortfolio
                          // ?
                          parseOutput(totalValue, 2).toLocaleString()
                          // : parseOutput(
                          //     capitalAmount - cashAmount,
                          //     2
                          //   ).toLocaleString()
                        }
                      </StyledTypography>
                    </Stack>
                  </Box>
                </Stack>
                {!isLive ? (
                  <Stack
                    flexDirection="row"
                    alignItems="center"
                    justifyContent="space-between"
                  >
                    <Stack
                      direction="row"
                      alignItems={isXMobile ? 'flex-start' : 'center'}
                      justifyContent="flex-start"
                      gap={0.5}
                    >
                      <StyledTypography>
                        Available Cash({currency}):
                      </StyledTypography>
                      {showCapital ? (
                        <StyledNumberField
                          type="number"
                          value={parseOutput(capitalInput, 2)}
                          endAdornment={
                            <InputAdornment position="end">
                              {currencySymbol}
                            </InputAdornment>
                          }
                          inputProps={{
                            min: 0,
                            step: 0.01,
                          }}
                          sx={{
                            width: 200,
                          }}
                          inputMode="numeric"
                          pattern="[0-9]*"
                          variant="standard"
                          onChange={handleChangeCapital}
                          onKeyDown={handleKeyDownCapital}
                        />
                      ) : (
                        `${currencySymbol}${parseOutput(
                          cashAmount,
                          2
                        ).toLocaleString()}`
                      )}
                    </Stack>
                    {!isLive &&
                      (!showCapital ? (
                        <MButton
                          purple="true"
                          width={isXMobile ? '100%' : '200px'}
                          onClick={() => {
                            setShowCapital(true);
                            setCapitalInput(cashAmount);
                          }}
                        >
                          Edit Cash Position
                        </MButton>
                      ) : (
                        <MButton
                          purple="true"
                          width="200px"
                          onClick={handleConfirmCapital}
                        >
                          Confirm
                        </MButton>
                      ))}
                  </Stack>
                ) : null}
              </Stack>
              <PortfolioTable
                assetsData={assetsData}
                totalPercentage={totalPercentage}
                totalValue={totalValue}
                capitalAmount={parseNumber(capitalAmount)}
                cashAmount={parseNumber(cashAmount)}
                redistributeAssets={redistributeAssets}
                isExisting={isExistingPortfolio}
                isLive={isLive}
                isDisable={
                  !isLive && isTrack && capitalAmount > 0 && showCapital
                }
                onReset={handleReset}
              />
              <MButton purple="true" width="100%" type="submit">
                Add
              </MButton>
            </Stack>
          </Box>
        </Grid>
        <Grid item xs={12} sm={12} md={5}>
          <Box
            sx={{
              position: 'relative',
              height: '100%',
              padding: 2,
              background: theme.palette.lightGrey,
              borderRadius: 2,
              minHeight: 500,
            }}
          >
            <DialogTitle sx={{ paddingX: 0, paddingTop: isXMobile ? 2 : 0 }}>
              Portfolio Preview
            </DialogTitle>
            <CenterBox>
              <PieChartEl
                data={portfolioData}
                width={isXMobile ? 300 : 350}
                height={isXMobile ? 300 : 350}
              />
            </CenterBox>
          </Box>
        </Grid>
      </Grid>
      {isSubmitting && (
        <CenterBox>
          <CheckCircleIcon
            color="secondary"
            fontSize="large"
            sx={{ width: 100, height: 100, marginBottom: 1 }}
          />
          <StyledMessage>
            {!isExistingPortfolio
              ? 'You have successfully created a new virtual portfolio strategy'
              : isLive
              ? 'You have successfully created a new live portfolio strategy'
              : 'You have successfully added new asset positions to your portfolio'}
          </StyledMessage>
          <Box sx={{ margin: '16px auto', width: 'fit-content' }}>
            <MButton purple="true" width="fit-content" onClick={handleClose}>
              Go to the Overview page
            </MButton>
          </Box>
        </CenterBox>
      )}
    </CDialog>
  );
};

export default PortfolioModal;
