import React, { useState, useEffect, useMemo } from 'react';
import { useNavigate } from 'react-router-dom';
import { useSelector, useDispatch } from 'react-redux';
import {
  Stack,
  Grid,
  InputAdornment,
  Box,
  DialogTitle,
  ButtonGroup,
  Button,
  useMediaQuery,
} from '@mui/material';
import { useTheme } from '@mui/material/styles';
import { CheckCircle as CheckCircleIcon } from '@mui/icons-material';
import CDialog from '../../../CDialog';
import MButton from '../../../../Common/MButton';
import PortfolioTable from './PortfolioTable';
import PieChartEl from '../../../../PieChartEl';
import {
  addPortfolioCoin,
  addPortfolioCoins,
  removePortfolioCoins,
  updateAllocation,
} from '../../../../../reducers/portfolioReducer';
import {
  setNotificationShow,
  setNotification,
  setUnfinishedPortfolio,
} from '../../../../../reducers/globalReducer';
import {
  StyledTypography,
  StyledTextField,
  StyledMessage,
  CenterBox,
} from '../styles';
import { StyledNumberField } from '../../PortfolioTable/components/PortfolioTableItem/styles';
import { parseNumber, parseOutput } from '../../../../../utils/parseNumber';
import { updatePortfolio } from '../../../../../lib/assetsApi';
import { getAuthToken } from '../../../../../lib/auth';
import { handleKeyDownNumber } from '../../../../../utils/keyEvent';

const PortfolioModal = ({
  open,
  onClose,
  handleShowLoading,
  handleFetchPortfolio,
  unfinishedPortfolio,
}) => {
  const theme = useTheme();
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const isXMobile = useMediaQuery((th) => th.breakpoints.down('sm'));
  const isMobile = useMediaQuery((th) => th.breakpoints.down('md'));
  const assetsData = useSelector((state) => state.portfolio.list);
  const assetsDataBackup = useSelector((state) => state.portfolio.listBackup);
  const portfolios = useSelector((state) => state.portfolioExplorer);
  const token = getAuthToken();
  const focusedInput = useSelector((state) => state.global.currentFocus);
  const currentPortfolio = useSelector((state) => state.portfolio.current);
  const currency = useSelector((state) => state.currency);
  const currencySymbol = useMemo(
    () => (currency === 'USD' ? '$' : '€'),
    [currency]
  );
  const [isTrack, setIsTrack] = useState(true);
  const [showCapital, setShowCapital] = useState(false);
  // const [capitalInput, setCapitalInput] = useState(0);
  const [capitalAmount, setCapitalAmount] = useState(0);
  const [cashAmount, setCashAmount] = useState(0);
  const [investAddAmount, setInvestAddAmount] = useState(0);
  const [cashAddAmount, setCashAddAmount] = useState(0);
  const [portfolioData, setPortfolioData] = useState([]);
  const [title, setTitle] = useState('');
  const [error, setError] = useState(false);
  const [totalPercentage, setTotalPercentage] = useState(0);
  const [totalValue, setTotalValue] = useState(0);
  const [isSubmitting, setIsSubmitting] = useState(false);

  useEffect(() => {
    setIsTrack(false);

    setCapitalAmount(currentPortfolio.capital);
    // const cashIndex = assetsData.findIndex((el) => el.id === currency);
    // if (cashIndex !== -1) {
    //   setCapitalInput(assetsData[cashIndex].amount);
    // } else {
    //   setCapitalInput(currentPortfolio.capital);
    // }
    setTitle(currentPortfolio.name);
  }, [currentPortfolio]);

  useEffect(() => {
    // calculate current total percentage
    const newAssetsPercentage = assetsData
      .map((el) => el.new_percentage || el.percentage)
      .reduce((prev, curr) => {
        return parseNumber(prev) + parseNumber(curr);
      }, 0);
    setTotalPercentage(newAssetsPercentage);
    const filteredData = (assets, applyFilter) => {
      if (applyFilter) {
        return assets.filter((el) => el.id !== currency);
      }
      return assets;
    };

    const newTotalValue = filteredData(assetsData, false)
      .map((el) => el.amount)
      .reduce((prev, curr) => {
        return parseNumber(prev) + parseNumber(curr);
      }, 0);
    setTotalValue(newTotalValue);

    // calculate the value and percentage of cash
    // if (newAssetsPercentage > 0 && capitalAmount > 0) {
    //   setCapitalInput(
    //     parseFloat((capitalAmount * (100 - newAssetsPercentage)) / 100)
    //   );
    // }

    // merge duplicated datas
    const dataToUpdate = assetsData.reduce((accumulator, el) => {
      const existingIndex = accumulator.findIndex((item) => item.id === el.id);
      if (existingIndex !== -1) {
        accumulator[existingIndex].percentage += parseNumber(el.percentage);
        accumulator[existingIndex].quantity += parseNumber(el.quantity);
        accumulator[existingIndex].amount += parseNumber(el.amount);
      } else {
        accumulator.push({
          id: el.id,
          displayName: el.displayName,
          assetClass: el.assetClass,
          tags: el.tags,
          price: el.price,
          percentage: parseNumber(el.percentage),
          quantity: parseNumber(el.quantity),
          amount: parseNumber(el.amount),
          ticker: el.ticker,
          logo: el.logo,
        });
      }
      return accumulator;
    }, []);
    setPortfolioData(dataToUpdate);

    const cashIndex = assetsData.findIndex((el) => el.id === currency);
    if (cashIndex !== -1) {
      setCashAmount(assetsData[cashIndex].amount);
    } else {
      setCashAmount(0);
    }
  }, [assetsData]);

  useEffect(() => {
    // if amount and quantity editing, redistribute percentage
    if (focusedInput.includes('amount') || focusedInput.includes('quantity')) {
      // redistributePercentage();
    }
  }, [totalValue]);

  useEffect(() => {
    if (unfinishedPortfolio) {
      setTitle(unfinishedPortfolio.title);
      setIsTrack(unfinishedPortfolio.tracked);
      setCapitalAmount(parseFloat(unfinishedPortfolio.capital));
    }
  }, [unfinishedPortfolio]);

  // const redistributeAll = (data) => {
  //   const value = capitalAmount;
  //   assetsData.map((el) => {
  //     if (el.uuid === data.uuid) {
  //       const newPercentage = parseNumber((el.amount / value) * 100);
  //       const focusData = {
  //         uuid: data.uuid,
  //         newPercentage,
  //         oldPercentage: data.oldPercentage,
  //       };
  //       redistributeQuantityValue(focusData);
  //       return dispatch(
  //         updateAllocation(el.uuid, newPercentage, el.quantity, el.amount)
  //       );
  //     }
  //     return 0;
  //   });
  // };

  // const redistributePercentage = () => {
  //   const value = capitalAmount;
  //   assetsData.map((el) => {
  //     const newPercentage = parseNumber((el.amount / value) * 100);
  //     return dispatch(
  //       updateAllocation(el.uuid, newPercentage, el.quantity, el.amount)
  //     );
  //   });
  // };

  // const redistributeQuantityValue = (data) => {
  //   const value = capitalAmount;
  //   assetsData.map((el) => {
  //     /** set percentage, amount, quantity to current focused one */
  //     if (el.uuid === data.uuid) {
  //       const newAmount = parseNumber(
  //         (value * parseFloat(el.percentage)) / 100
  //       );
  //       const newQuantity = parseNumber(newAmount / el.price);
  //       return dispatch(
  //         updateAllocation(el.uuid, el.percentage, newQuantity, newAmount)
  //       );
  //     }
  //     /** redistribute other assets based on current editing */
  //     const newPercentage = parseFloat(
  //       ((100 - parseNumber(data.newPercentage)) *
  //         (100 / (100 - parseNumber(data.oldPercentage))) *
  //         el.percentage) /
  //         100
  //     );
  //     // const newAmount = parseFloat((value * newPercentage) / 100);
  //     // const newQuantity = parseFloat(newAmount / el.price);
  //     return dispatch(
  //       updateAllocation(el.uuid, newPercentage, el.quantity, el.amount)
  //     );
  //   });
  // };

  const handleChangeInvestAmount = (event) => {
    handleCheckValid(event);
    setInvestAddAmount(event.target.value);
  };

  const handleChangeCashAmount = (event) => {
    handleCheckValid(event);
    setCashAddAmount(event.target.value);
  };

  const handleCheckValid = (event) => {
    if (!event.target.value) {
      dispatch(setNotificationShow(true));
      dispatch(setNotification('Please input valid number'));
    }
  };

  const handleSubmit = async (event) => {
    event.preventDefault();

    if (title.trim() === '') {
      setError(true);
    } else {
      let portfolioToUpdate = portfolios.myPortfolio.find(
        (element) => element.id === currentPortfolio.id
      );
      const emptyPortfolioItem = portfolioData.filter((el) => !el.percentage);
      if (emptyPortfolioItem.length > 0) {
        dispatch(setNotificationShow(true));
        dispatch(setNotification('All of assets must have allocation'));
      } else {
        portfolioToUpdate = {
          ...portfolioToUpdate,
          assets: portfolioData.filter((el) => el.percentage),
          capital: parseNumber(capitalAmount),
          name: title,
        };

        if (token) {
          handleShowLoading(true);
          const portfolioUpdated = await updatePortfolio(
            portfolioToUpdate,
            token
          );
          if (
            portfolioUpdated.status === 200 ||
            portfolioUpdated.status === 201
          ) {
            dispatch(setNotificationShow(false));
            setIsSubmitting(true);
            handleFetchPortfolio();
          } else {
            dispatch(setNotificationShow(true));
            dispatch(setNotification(portfolioUpdated.error));
          }
          handleShowLoading(false);
        } else {
          navigate('/login');
        }
      }
    }
  };

  const handleChangeName = (event) => {
    setTitle(event.target.value);
    setError(false);
  };

  const handleKeyDownName = (event) => {
    if (event.key === 'Enter') {
      event.preventDefault();
    }
  };

  const handleReset = () => {
    setCapitalAmount(currentPortfolio.capital);
    dispatch(addPortfolioCoins(assetsDataBackup));
  };

  const handleClose = () => {
    setError(false);
    setTitle('');
    setCapitalAmount(0);
    setCashAmount(0);
    setTotalValue(0);
    setShowCapital(false);
    setIsTrack(true);
    dispatch(addPortfolioCoins([]));
    dispatch(setUnfinishedPortfolio(false));
    if (isSubmitting) {
      navigate('/overview');
    }
    setIsSubmitting(false);
    onClose();
  };

  const handleInvestAmount = (type) => {
    if (type === 'invest' && investAddAmount > cashAmount) {
      dispatch(setNotificationShow(true));
      dispatch(setNotification('You need to add more cash'));
    } else if (
      type === 'divest' &&
      investAddAmount > capitalAmount - cashAmount
    ) {
      dispatch(setNotificationShow(true));
      dispatch(setNotification('You need to invest more capital'));
    } else {
      const newCashAmount =
        cashAmount + parseFloat(investAddAmount) * (type === 'divest' ? 1 : -1);
      if (newCashAmount > 0) {
        const updatedPercentage = parseFloat(
          (newCashAmount / capitalAmount) * 100
        );
        const cashIndex = assetsData.findIndex((el) => el.id === currency);
        if (cashIndex !== -1) {
          updateCash(cashIndex, updatedPercentage, newCashAmount);
        } else {
          addCash(updatedPercentage, newCashAmount);
        }
        // recalculate percentage for assets except cash
        const oldCashPercentage = (cashAmount / capitalAmount) * 100;
        assetsData
          .filter((el) => el.id !== currency)
          .map((el) => {
            const newPercentage = parseNumber(
              (el.percentage / (100 - oldCashPercentage)) *
                (100 - updatedPercentage)
            );
            const newAmount = parseNumber(
              (newPercentage / 100) * capitalAmount
            );
            const newQuantity = parseNumber(newAmount / el.price);
            return dispatch(
              updateAllocation(el.uuid, newPercentage, newQuantity, newAmount)
            );
          });
      }
      setCashAmount(newCashAmount);
      if (newCashAmount === 0) dispatch(removePortfolioCoins(currency));
    }
  };

  const handleCashAmount = (type) => {
    if (type === 'withdraw' && cashAddAmount > cashAmount) {
      dispatch(setNotificationShow(true));
      dispatch(
        setNotification(`You can't withdraw more than the $${cashAmount}`)
      );
    } else {
      const newCapitalAmount =
        capitalAmount + parseFloat(cashAddAmount) * (type === 'add' ? 1 : -1);
      const newCashAmount =
        cashAmount + parseFloat(cashAddAmount) * (type === 'add' ? 1 : -1);
      setCapitalAmount(newCapitalAmount);
      const cashIndex = assetsData.findIndex((el) => el.id === currency);
      const updatedPercentage = parseFloat(
        (newCashAmount / newCapitalAmount) * 100
      );
      if (cashIndex !== -1) {
        updateCash(cashIndex, updatedPercentage, newCashAmount);
      } else {
        addCash(updatedPercentage, newCashAmount);
      }
      // recalculate percentage for assets except cash
      const oldCashPercentage = (cashAmount / capitalAmount) * 100;
      assetsData
        .filter((el) => el.id !== currency)
        .map((el) => {
          const newPercentage = parseNumber(
            (el.percentage / (100 - oldCashPercentage)) *
              (100 - updatedPercentage)
          );
          const newAmount = parseNumber((newPercentage / 100) * capitalAmount);
          const newQuantity = parseNumber(newAmount / el.price);
          return dispatch(
            updateAllocation(el.uuid, newPercentage, newQuantity, newAmount)
          );
        });

      if (parseOutput(cashAmount, 2) === parseOutput(cashAddAmount, 2)) {
        // dispatch(removePortfolioCoins(currency));
      }
    }
  };

  const updateCash = (index, percentage, amount) => {
    dispatch(
      updateAllocation(assetsData[index].uuid, percentage, amount, amount)
    );
  };

  const addCash = (percentage, amount) => {
    dispatch(
      addPortfolioCoin([
        {
          id: currency,
          displayName: currency,
          ticker: currency,
          uuid: currency,
          assetClass: 1,
          tags: [],
          percentage,
          price: 1,
          quantity: amount,
          amount,
          selected: true,
        },
      ])
    );
  };

  return (
    <CDialog
      open={open}
      width="100%"
      height="100%"
      bgcolor={isSubmitting ? 'purple' : 'transparent'}
      onClose={handleClose}
    >
      <Grid
        container
        columnSpacing={isMobile ? 0 : 2}
        rowSpacing={isMobile ? 2 : 0}
        sx={{ opacity: isSubmitting ? 0 : 1 }}
      >
        <Grid item xs={12} sm={12} md={7}>
          <Box
            sx={{
              height: '100%',
              padding: 2,
              background: 'white',
              borderRadius: 2,
            }}
          >
            <DialogTitle sx={{ paddingX: 0, paddingTop: isXMobile ? 2 : 0 }}>
              {isSubmitting ? '' : 'Manage Portfolio'}
            </DialogTitle>
            <Stack spacing={3} component="form" onSubmit={handleSubmit}>
              <StyledTextField
                fullWidth
                label="Portfolio Name"
                onChange={handleChangeName}
                autoFocus={currentPortfolio.name ? true : false}
                value={title}
                onKeyDown={handleKeyDownName}
                error={error}
                helperText={error ? 'Portfolio name cannot be empty' : ''}
              />
              <Stack rowGap={1}>
                <Stack
                  direction={isXMobile ? 'column' : 'row'}
                  alignItems="center"
                  justifyContent="space-between"
                  gap={3}
                >
                  <Box>
                    <Stack
                      direction="row"
                      alignItems={isXMobile ? 'flex-start' : 'center'}
                      justifyContent="flex-start"
                      gap={1}
                    >
                      <StyledTypography sx={{ whiteSpace: 'nowrap' }}>
                        Invested Capital:
                        {currencySymbol}
                        {
                          // parseOutput(totalValue, 2).toLocaleString()
                          parseOutput(
                            capitalAmount - cashAmount,
                            2
                          ).toLocaleString()
                        }
                      </StyledTypography>
                    </Stack>
                  </Box>
                  <Stack flexDirection="row" columnGap={1}>
                    <StyledNumberField
                      type="number"
                      endAdornment={
                        <InputAdornment position="end">
                          {currencySymbol}
                        </InputAdornment>
                      }
                      inputProps={{
                        min: 0,
                        step: 0.01,
                      }}
                      sx={{
                        width: 100,
                      }}
                      inputMode="numeric"
                      pattern="[0-9]*"
                      variant="standard"
                      onChange={handleChangeInvestAmount}
                      onKeyDown={handleKeyDownNumber}
                    />
                    <ButtonGroup
                      variant="outlined"
                      size="small"
                      aria-label="Invest button group"
                    >
                      <Button onClick={() => handleInvestAmount('invest')}>
                        Invest
                      </Button>
                      <Button onClick={() => handleInvestAmount('divest')}>
                        Divest
                      </Button>
                    </ButtonGroup>
                  </Stack>
                </Stack>
                <Stack
                  flexDirection="row"
                  alignItems="center"
                  justifyContent="space-between"
                >
                  <Stack
                    direction="row"
                    alignItems={isXMobile ? 'flex-start' : 'center'}
                    justifyContent="flex-start"
                    gap={0.5}
                  >
                    <StyledTypography>
                      Available Cash({currency}):
                    </StyledTypography>
                    {currencySymbol}
                    {parseOutput(cashAmount, 2).toLocaleString()}
                  </Stack>

                  <Stack flexDirection="row" columnGap={1}>
                    <StyledNumberField
                      type="number"
                      endAdornment={
                        <InputAdornment position="end">
                          {currencySymbol}
                        </InputAdornment>
                      }
                      inputProps={{
                        min: 0,
                        step: 0.01,
                      }}
                      sx={{
                        width: 100,
                      }}
                      inputMode="numeric"
                      pattern="[0-9]*"
                      variant="standard"
                      onChange={handleChangeCashAmount}
                      onKeyDown={handleKeyDownNumber}
                    />
                    <ButtonGroup
                      variant="outlined"
                      size="small"
                      aria-label="Cash button group"
                    >
                      <Button onClick={() => handleCashAmount('add')}>
                        Add
                      </Button>
                      <Button onClick={() => handleCashAmount('withdraw')}>
                        Withdraw
                      </Button>
                    </ButtonGroup>
                  </Stack>
                </Stack>
              </Stack>
              <PortfolioTable
                assetsData={assetsData}
                totalPercentage={totalPercentage}
                totalValue={totalValue}
                capitalAmount={parseNumber(capitalAmount)}
                cashAmount={parseNumber(cashAmount)}
                isDisable={isTrack && capitalAmount > 0 && showCapital}
                onReset={handleReset}
              />
              <MButton purple="true" width="100%" type="submit">
                Reallocate
              </MButton>
            </Stack>
          </Box>
        </Grid>
        <Grid item xs={12} sm={12} md={5}>
          <Box
            sx={{
              position: 'relative',
              height: '100%',
              padding: 2,
              background: theme.palette.lightGrey,
              borderRadius: 2,
              minHeight: 500,
            }}
          >
            <DialogTitle sx={{ paddingX: 0, paddingTop: isXMobile ? 2 : 0 }}>
              Portfolio Preview
            </DialogTitle>
            <CenterBox>
              <PieChartEl
                data={portfolioData}
                width={isXMobile ? 300 : 350}
                height={isXMobile ? 300 : 350}
              />
            </CenterBox>
          </Box>
        </Grid>
      </Grid>
      {isSubmitting && (
        <CenterBox>
          <CheckCircleIcon
            color="secondary"
            fontSize="large"
            sx={{ width: 100, height: 100, marginBottom: 1 }}
          />
          <StyledMessage>
            You have successfully updated your portfolio
          </StyledMessage>
          <Box sx={{ margin: '16px auto', width: 'fit-content' }}>
            <MButton purple="true" width="fit-content" onClick={handleClose}>
              Go to the Overview page
            </MButton>
          </Box>
        </CenterBox>
      )}
    </CDialog>
  );
};

export default PortfolioModal;
