export const formatNumber = (number) => {
  if (number === 0) return number;
  if (number > 0 && number < 0.01) return number.toExponential(2);
  const formattedNumber = number.toLocaleString(undefined, {
    minimumFractionDigits: 2,
    maximumFractionDigits: 2,
  });
  return formattedNumber;
};

export const getSuperscriptNumber = (number, index) => {
  const parts = number.toExponential(2).split('e');
  return parts[index];
};

export const getExponentNumber = (number) => {
  const parts = number.toExponential(2).split('e-');
  return parts[1] ? Number(parts[1]) + 2 : 2;
};

export const parseNumber = (input) => {
  const inputToNumber = parseFloat(input);
  if (Number.isNaN(inputToNumber)) return 0;
  return inputToNumber;
};

export const parseOutput = (output, fixed) => {
  const parsedOutput = parseFloat(output);
  if (Number.isNaN(parsedOutput)) return output;
  // if (parsedOutput > 1) {
  //   return parseFloat(parsedOutput.toFixed(fixed)).toString();
  // }
  return parseFloat(parsedOutput.toFixed(fixed));
};

export const parseOutputNoRounding = (num, fixed) => {
  const parsedOutput = parseFloat(num);
  if (Number.isNaN(parsedOutput)) return num;

  const numStr = num.toString();
  const [integerPart, fractionalPart] = numStr.split('.');

  if (!fractionalPart || fractionalPart.length < fixed) {
    return numStr;
  }

  const truncatedFractionalPart = fractionalPart.slice(0, fixed);
  return parseFloat(`${integerPart}.${truncatedFractionalPart}`);
};

export const formatBigNumber = (number, round) => {
  let unit = 1000;
  let unitSymbol = 'K';

  if (number > 100000) {
    unit = 1000000;
    unitSymbol = 'M';
  }
  if (number > 100000000) {
    unit = 1000000000;
    unitSymbol = 'B';
  }

  if (number > 1000) {
    const formattedNumber = (number / unit).toLocaleString(undefined, {
      minimumFractionDigits: 0,
      maximumFractionDigits: round,
    });

    return `${formattedNumber}${unitSymbol}`;
  }

  return Math.floor(number);
};

export const formatNumberByCheckList = (list, number) => {
  let round = 1;
  let prevFormattedNumber = formatBigNumber(number, round);

  // eslint-disable-next-line no-constant-condition
  while (true) {
    let foundRepeated = false;
    for (let i = 0; i < list.length; i += 1) {
      const formattedNumber = formatBigNumber(list[i], round);
      if (prevFormattedNumber === formattedNumber && list[i] !== number) {
        round += 1;
        foundRepeated = true;
        break;
      }
    }
    if (!foundRepeated) break;
    prevFormattedNumber = formatBigNumber(number, round);
  }

  return formatBigNumber(number, round);
};
